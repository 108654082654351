import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/store/useAuthStore";
import NotFound from "@/views/general/not-found/NotFound.vue";
import OpenIdLoginPage from "@/views/general/login-page/OpenIdLoginPage.vue";
import HomePage from "@/views/general/homepage/HomePage.vue";

const routes = [
  {
    path: "/",
    name: "openIdLoginPage",
    component: OpenIdLoginPage,
    meta: {
      title: "Anmelden mit CONROO! | CONROO Webapp",
      requiresAuth: false,
    },
  },
  {
    path: "/auth/oidc",
    name: "openIdLoginPage",
    component: OpenIdLoginPage,
    meta: {
      title: "Anmelden mit CONROO! | CONROO Webapp",
      requiresAuth: false,
    },
  },
  {
    path: "/homepage",
    name: "homePage",
    component: HomePage,
    meta: {
      title: "Home | CONROO Webapp",
      requiresAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFound,
    name: "notFound",
    meta: {
      title: "Seite nicht gefunden",
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = (to.meta!.title as string) ?? "CONROO TERMINAL APP";

  const requiresAuth = to.meta!.requiresAuth;

  const authStore = useAuthStore();
  const user = authStore.authUser;
  if (!user) {
    if (to.path === "/" || requiresAuth) {
      next("/auth/oidc");
    }
  }
  //TODO: shouldnt be in here, takes time to route to page
  if (to.path === "/" && user) {
    next("/homepage");
  }

  next();
});

export default router;
