import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import { VAutocomplete } from "vuetify/components";

import vuetify from "./plugins/vuetify";
import router from "./router";
import i18n from "./plugins/i18n";
import Vue3Lottie from "vue3-lottie";

import { useAuthStore } from "./store/useAuthStore";
import { createVPhoneInput } from "v-phone-input";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { auth } from "../firebase";

import "flag-icons/css/flag-icons.min.css";
import "v-phone-input/dist/v-phone-input.css";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const vPhoneInput = createVPhoneInput({
  countryIconMode: "svg",
});

const app = createApp(App)
  .use(router)
  .use(vuetify)
  .use(i18n)
  .use(pinia)
  .use(vPhoneInput)
  .use(Vue3Lottie);

app.component("VAutocomplete", VAutocomplete);

auth.onAuthStateChanged(async user => {
  const authStore = useAuthStore();
  if (!app) {
    if (user) {
      const localUser = await authStore.getUser();
      authStore.setUser({
        userId: user?.uid,
        emailVerified: user?.emailVerified,
        accessToken: await user?.getIdToken(),
        userData: localUser?.userData,
      });
    } else {
      authStore.setUser(null);
    }
  }
});

app.mount("#app");
