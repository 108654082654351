<template>
  <v-container fluid class="fill-height overflow-hidden">
    <v-row>
      <TheMainHeader
        class="text-left pl-15 pt-10 pr-15"
        :text="t('home')"
        data-testid="test-home"
      />
    </v-row>

    <div @click="logOut" data-testid="test-logout">
      <v-icon color="#1e2f55" size="30">mdi-logout</v-icon>
      <label>{{ t("logout") }}</label>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { useAuthStore } from "@/store/useAuthStore";
import TheMainHeader from "@/components/TheMainHeader.vue";
import { useI18n } from "vue-i18n";

const authStore = useAuthStore();
const router = useRouter();
const { t } = useI18n();

const basePath = "/";

const logOut = async () => {
  await authStore.logout();
  await router.push(basePath);
};
</script>

<style lang="scss" scoped>
.router-link {
  text-decoration: none;
  color: inherit;
}
</style>
