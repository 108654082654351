import { Ref } from "vue";

export function useProcess(
  errorMessage: Ref<string>,
  loadingState: Ref<boolean>,
) {
  const startProcess = () => {
    errorMessage.value = "";
    loadingState.value = true;
  };

  const stopProcess = () => {
    loadingState.value = false;
  };

  return { startProcess, stopProcess };
}
