<template>
  <v-card :width="calculateWidth" :loading="loadingLoginButton">
    <v-main fluid class="fill-height pa-4">
      <v-row>
        <v-col lg="6" md="12" class="d-flex align-center justify-center">
          <div class="w-100">
            <div
              class="text-center text-h5 font-weight-bold"
              data-testid="test-login-title"
            >
              {{ t("oidc.one_time_password") }}
            </div>
            <div class="text-center pt-2">
              {{ t("oidc.one_time_password_description") }}
            </div>
            <v-card-text class="pt-4">
              <v-form v-model="valid" @submit.prevent>
                <v-otp-input
                  v-model="optCode"
                  :disabled="loadingPhoneLogin"
                  :loading="loadingPhoneLogin"
                />

                <TheButton
                  :loading="loadingPhoneLogin"
                  @clicked-button="confirmOpt"
                  class="mt-4"
                  :size="'default'"
                  :disabled="optCode.length !== 6 || loadingPhoneLogin"
                  :elevation="0"
                  :title="t('oidc.confirm')"
                />
                <TheButton
                  :loading="loadingPhoneLogin"
                  @clicked-button="sendMessage"
                  class="mt-4"
                  :outlined="true"
                  :size="'default'"
                  :elevation="0"
                  :disabled="countdown > 0 || loadingPhoneLogin"
                  :title="`${t('oidc.resend_code')} ${countdown > 0 ? `(${countdown})` : ''}`"
                />
              </v-form>
            </v-card-text>

            <TheErrorBox class="ml-15 mt-2" :title="loginPhoneErrorMessage" />

            <TheMainText
              @click="changeFormView"
              :disabled="loadingPhoneLogin"
              :clickable="true"
              :title="t('oidc.back_to_login')"
            />
          </div>
        </v-col>
        <v-col
          v-if="!$vuetify.display.mdAndDown"
          cols="6"
          class="d-flex align-center justify-center"
        >
          <div class="animation-container rounded-lg">
            <Vue3Lottie
              :animation-data="CodeInput"
              :loop="true"
              :autoplay="true"
              :height="450"
              :max-height="450"
            />
          </div>
        </v-col>
      </v-row>
    </v-main>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed, PropType, toRefs, onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { Vue3Lottie } from "vue3-lottie";

import { getErrorByType } from "@/utils/error-utils";
import CodeInput from "../../../lottie/CodeInputAnimation.json";

// Components
import TheButton from "@/components/TheButton.vue";
import TheMainText from "@/components/TheMainText.vue";
import TheErrorBox from "@/components/TheErrorBox.vue";
import { getViewModel } from "@/views/general/login-page/login-page-logic";
import firebase from "firebase/compat/app";
import { useDisplay } from "vuetify";
import { useProcess } from "@/composables/useProcess";

const props = defineProps({
  loadingLoginButton: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object as PropType<{
      confirmationResult: firebase.auth.ConfirmationResult;
      phoneNumber: string;
      recaptchaVerifier: firebase.auth.RecaptchaVerifier;
    }>,
    default: () => null,
  },
});

const { t } = useI18n();
const { name } = useDisplay();
const viewModel = getViewModel();
const countdown = ref(60);

let interval: NodeJS.Timeout | number | null = null;

const valid = ref(false);
const { data: newData } = toRefs(props);

const emit = defineEmits(["change-view", "redirect"]);

// Local state
const optCode = ref("");
const loadingPhoneLogin = ref(false);
const loginPhoneErrorMessage = ref("");

// Methods
const { startProcess, stopProcess } = useProcess(
  loginPhoneErrorMessage,
  loadingPhoneLogin,
);

const startCountdown = () => {
  const endTime = Date.now() + 60000;
  countdown.value = 60;
  if (interval) {
    clearInterval(interval);
  }

  interval = setInterval(() => {
    const timeLeft = Math.round((endTime - Date.now()) / 1000);
    if (timeLeft >= 0) {
      countdown.value = timeLeft;
    } else {
      clearInterval(interval!);
    }
  }, 1000);
};

const confirmOpt = async () => {
  startProcess();
  try {
    if (props.data.confirmationResult) {
      await viewModel.loginWithPhoneCode(
        props.data.confirmationResult,
        optCode.value,
      );
      emit("redirect");
    }
  } catch (e) {
    loginPhoneErrorMessage.value = t(getErrorByType(e)) as string;
  } finally {
    stopProcess();
  }
};

const sendMessage = async () => {
  if (loadingPhoneLogin.value) {
    return;
  }
  startProcess();
  try {
    const confirmation = await viewModel.loginWithPhone(
      props.data.phoneNumber,
      props.data.recaptchaVerifier!,
    );
    if (confirmation) {
      newData.value.confirmationResult = confirmation;
    }
    await viewModel.showMessageSentSnackbar();
    startCountdown();
  } catch (e) {
    loginPhoneErrorMessage.value = t(getErrorByType(e)) as string;
  } finally {
    stopProcess();
  }
};

const changeFormView = () => {
  emit("change-view", "phone");
};

const calculateWidth = computed(() => {
  const breakpoints = {
    xxl: "40vw",
    xl: "50vw",
    lg: "60vw",
    md: "60vw",
    sm: "80vw",
    xs: "90vw",
  };

  return breakpoints[name.value] || "100vw";
});

onMounted(() => {
  startCountdown();
});

onUnmounted(() => {
  if (interval) {
    clearInterval(interval);
  }
});
</script>

<style lang="scss" scoped>
.animation-container {
  background-color: #14005c;
}
</style>
