<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center" class="fill-height">
      <v-col class="main-title" sm="6" md="3">
        <v-col>
          {{ t("pageNotFound") }}
        </v-col>
        <v-col>
          <v-img fluid contain src="../../../assets/main/error-404.png" />
        </v-col>
        <v-col>
          <TheButton
            @clicked-button="goBack"
            :loading="false"
            :title="t('goBack')"
          />
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import TheButton from "@/components/TheButton.vue";

const router = useRouter();
const { t } = useI18n();

const goBack = () => {
  router.go(-1);
};
</script>

<style lang="scss" scoped>
.main-title {
  font-weight: bold;
  font-size: 28px;
  color: #00295b;
}
</style>
