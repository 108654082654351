<!--//TODO:width responsive-->
<template>
  <v-alert
    color="red"
    density="default"
    rounded
    width="300"
    elevation="2"
    :model-value="showError"
    variant="outlined"
    type="error"
  >
    {{ title }}
  </v-alert>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  title: string;
}>();

const showError = computed(() => {
  return props.title.length !== 0;
});
</script>
