import { OidcMobileApi, UsersApi } from "../generated";
import axiosApiInstance from "@/services/client/configs/axios-interceptor";

export const usersApiClient = new UsersApi(
  {},
  axiosApiInstance.defaults.baseURL,
  axiosApiInstance,
);

export const oidcLoginService = new OidcMobileApi(
  {},
  axiosApiInstance.defaults.baseURL,
  axiosApiInstance,
);
