<template>
  <v-main fluid class="fill-height main">
    <v-container fluid class="fill-height">
      <v-row
        align="center"
        justify="center"
        class="justify-center align-center"
      >
        <div class="d-flex flex-column align-center">
          <p class="font-weight-bold text-h3 pb-8 text-white text-center">
            {{ $t("welcome_to_conroo") }}
          </p>
          <v-overlay
            v-model="loggedIn"
            class="d-flex align-center justify-center"
          >
            <LoginAsUser :username="username" />
          </v-overlay>
          <template v-if="!loggedIn">
            <LoginPhoneForm
              v-if="loginForm == 'phone'"
              @change-view="toggleView($event)"
              @code-sent="codeEvent($event)"
              @redirect="redirect()"
            />

            <ConfirmCode
              v-else-if="loginForm == 'confirm-code'"
              :data="necessaryData"
              @redirect="redirect()"
              @change-view="toggleView($event)"
            />

            <ForgotPasswordForm
              v-else-if="loginForm == 'forgot-password'"
              @changed-login-view="toggleView"
            />

            <LoginForm
              @changed-login-view="toggleView"
              v-else
              :error-messages="errorMessages"
              :login-error-message="loginErrorMessage"
              :loading-login-button="loadingLoginButton"
              @submit-login="login"
            />
          </template>
        </div>
      </v-row>
    </v-container>
  </v-main>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/store/useAuthStore";
import { useI18n } from "vue-i18n";
import firebase from "firebase/compat/app";

// Components
import LoginForm from "@/views/general/login-page/LoginForm.vue";
import ForgotPasswordForm from "@/views/general/login-page/ForgotPasswordForm.vue";
import LoginAsUser from "@/views/oidc/LoginAsUser.vue";
import LoginPhoneForm from "@/views/general/login-page/LoginPhoneForm.vue";
import ConfirmCode from "@/views/general/login-page/ConfirmCode.vue";

// Utils and Services
import { getErrorByType } from "@/utils/error-utils";
import { getViewModel as getOIDCViewModel } from "@/views/general/login-page/oidc-login-logic";
import { getViewModel as getLoginViewModel } from "@/views/general/login-page/login-page-logic";
import { AuthDetails } from "@/services/client/generated";
import { useProcess } from "@/composables/useProcess";

const oidcViewModel = getOIDCViewModel();
const loginViewModel = getLoginViewModel();

const authStore = useAuthStore();
const necessaryData = ref();
const route = useRoute();
const { t } = useI18n();

const username = ref("");
const loggedIn = ref(false);
const loginForm = ref("phone");
const errorMessages = ref("");
const loginErrorMessage = ref("");
const loadingLoginButton = ref(false);
const authDetails = reactive<AuthDetails>({
  codeChallenge: "",
  codeChallengeMethod: "",
  refreshToken: "",
  expiresIn: 3600,
  redirectUri: "",
  idToken: "",
  nonce: "",
  state: "",
});

onMounted(async () => {
  authDetails.codeChallenge = route.query.codeChallenge as string;
  authDetails.codeChallengeMethod = route.query.codeChallengeMethod as string;
  authDetails.redirectUri = route.query.redirectUri as string;
  authDetails.nonce = route.query.nonce as string;
  authDetails.state = route.query.state as string;

  const user = authStore.authUser;
  if (user) {
    try {
      loggedIn.value = true;
      startProcess();
      await redirect();
    } catch (e) {
      await loginViewModel.logout();
      loginErrorMessage.value = t(getErrorByType(e)) as string;
    } finally {
      stopProcess();
    }
  }
});

const toggleView = (view = "login") => {
  loginForm.value = view;
};

const { startProcess, stopProcess } = useProcess(
  loginErrorMessage,
  loadingLoginButton,
);

const redirect = async () => {
  const user = authStore.authUser;
  username.value = user?.userData?.email
    ? user?.userData?.email
    : (user?.userData?.phoneNumber ?? "");
  const authDetailsUpdated: AuthDetails = {
    ...authDetails,
    idToken: user?.accessToken ?? "",
    refreshToken: localStorage.getItem("userRefreshToken")!,
  };

  loggedIn.value = true;
  const redirectUrl = await oidcViewModel.refreshToken(authDetailsUpdated);

  setTimeout(() => {
    window.location.href = redirectUrl as string;
  }, 500);
};

const login = async (payload: {
  email: string;
  password: string;
  validate: boolean | null;
}) => {
  if (!payload.validate) {
    loginErrorMessage.value = "";
    return;
  }

  if (loadingLoginButton.value) return;
  startProcess();

  try {
    await loginViewModel.login(payload.email, payload.password);
    await redirect();
  } catch (e) {
    await loginViewModel.logout();
    loginErrorMessage.value = t(getErrorByType(e)) as string;
  } finally {
    stopProcess();
  }
};

const codeEvent = (
  event: [
    firebase.auth.ConfirmationResult,
    string,
    firebase.auth.RecaptchaVerifier,
  ],
) => {
  necessaryData.value = event;
  toggleView("confirm-code");
};
</script>

<style scoped>
.main {
  background-image: url("../../../assets/main/conroo-background.svg");
  background-size: cover;
}
</style>
