<template>
  <v-app>
    <v-main>
      <router-view />
      <div id="recaptcha-container"></div>
    </v-main>
  </v-app>
</template>

<script lang="ts" setup></script>
