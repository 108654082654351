import { AuthStore, SnackbarStore } from "@/store/store-types";

import firebase from "firebase/compat/app";
import { UsersApi } from "@/services/client/generated";
import SnackbarType from "@/store/interfaces/snackbar-type";
import { Composer } from "vue-i18n";
import { useAuthStore } from "@/store/useAuthStore";
import { usersApiClient } from "@/services/client/configs/services";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import i18n from "@/plugins/i18n";

//TODO: inversion
export class LoginPageLogic {
  constructor(
    private authStore: AuthStore,
    private userService: UsersApi,
    private snackbarStore: SnackbarStore,
    private vueI18n: Composer,
  ) {}

  async login(email: string, password: string): Promise<void> {
    //TODO: check on why wwe have a null value
    await this.authStore.login({ email, password });
  }

  async loginWithPhone(
    phone: string,
    appVerifier: firebase.auth.RecaptchaVerifier,
  ): Promise<firebase.auth.ConfirmationResult | null> {
    return await this.authStore.loginWithPhone(phone, appVerifier);
  }

  async loginWithPhoneCode(
    confirmation: firebase.auth.ConfirmationResult | null,
    code: string,
  ): Promise<void> {
    await this.authStore.loginWithPhoneCode(confirmation, code);
  }

  async logout(): Promise<void> {
    await this.authStore.logout();
  }

  async resetPassword(email: string): Promise<void> {
    await this.userService.resetPassword({ email });
  }

  async showEmailSentSnackbar(): Promise<void> {
    this.snackbarStore.showSnackbar({
      text: this.vueI18n.t("emailSent") as string,
      snackbarType: SnackbarType.SUCCESS,
    });
  }

  async showMessageSentSnackbar(): Promise<void> {
    this.snackbarStore.showSnackbar({
      text: this.vueI18n.t("messageSent") as string,
      snackbarType: SnackbarType.SUCCESS,
    });
  }
}

let viewModel: LoginPageLogic | null = null;

export const getViewModel = (): LoginPageLogic => {
  if (!viewModel) {
    viewModel = new LoginPageLogic(
      useAuthStore(),
      usersApiClient,
      useSnackbarStore(),
      i18n.global as Composer,
    );
  }
  return viewModel;
};
