import { defineStore } from "pinia";
import UserAuth from "./interfaces/user-auth";
import { User } from "@/services/client/generated";
import { authService } from "@/services/business/auth-service";
import firebase from "firebase/compat";
import { usersApiClient } from "@/services/client/configs/services";

export interface AuthState {
  authUser: UserAuth | null;
  loading: boolean;
  errorMessage: string;
}

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    authUser: null as UserAuth | null,
    loading: false,
    errorMessage: "",
  }),

  actions: {
    setUser(user: UserAuth | null) {
      this.authUser = user;
    },

    setUserData(user: User) {
      if (this.authUser) {
        this.authUser = {
          ...this.authUser,
          userData: user,
        };
      }
    },

    async fetchUserData(user: firebase.User | null) {
      if (user) {
        const token = await authService.getToken();
        const localUser = await usersApiClient.getUserById(user?.uid ?? "");

        this.setUser({
          userId: user.uid,
          emailVerified: user.emailVerified,
          accessToken: token,
          userData: localUser.data,
        });

        localStorage.setItem("user", JSON.stringify(this.authUser));
      } else {
        this.setUser(null);
        localStorage.removeItem("user");
      }
    },

    async login(payload: { email: string; password: string }) {
      const { email, password } = payload;

      const user = await authService.login(email, password);

      if (!user) {
        throw new Error("User not found or invalid");
      }

      localStorage.setItem("userRefreshToken", user!.refreshToken);

      this.setUser({
        userId: user!.uid,
        emailVerified: user!.emailVerified,
        accessToken: await authService.getToken(),
      });

      const response = await usersApiClient.getUserById(user?.uid ?? "");
      this.setUserData(response.data);

      localStorage.setItem("user", JSON.stringify(this.authUser));
    },

    async loginWithPhone(
      phone: string,
      appVerifier: firebase.auth.RecaptchaVerifier,
    ): Promise<firebase.auth.ConfirmationResult | null> {
      this.loading = true;
      try {
        // Use the AuthService to sign in with phone number
        const confirmation = await authService.signInWithPhoneNumber(
          phone,
          appVerifier,
        );
        this.loading = false;
        return confirmation; // Return the confirmation result to be used later
      } catch (error) {
        if (error instanceof Error) {
          this.errorMessage = error.message || "Failed to send OTP";
        } else {
          this.errorMessage = "Failed to send OTP";
        }
        this.loading = false;
        return null;
      }
    },

    async loginWithPhoneCode(
      confirmation: firebase.auth.ConfirmationResult | null,
      code: string,
    ) {
      if (!confirmation) {
        this.errorMessage = "Invalid confirmation result.";
        return;
      }

      this.loading = true;
      try {
        const { user } = await confirmation.confirm(code); // Verify the OTP code

        if (user) {
          localStorage.setItem("userRefreshToken", user.refreshToken);

          const token = await authService.getToken();
          this.authUser = {
            userId: user.uid,
            emailVerified: user.emailVerified,
            accessToken: token,
            userData: undefined, // Will be fetched later
          };

          // Fetch user data from the API using the user ID
          const response = await usersApiClient.getUserById(user.uid);
          this.authUser = {
            ...this.authUser,
            userData: response.data,
          };

          // Save the user data in localStorage
          localStorage.setItem("user", JSON.stringify(this.authUser));
        }
      } catch (error) {
        if (error instanceof Error) {
          this.errorMessage = error.message || "Failed to verify OTP";
        } else {
          this.errorMessage = "Failed to verify OTP";
        }
      } finally {
        this.loading = false;
      }
    },

    async logout() {
      await authService.logout();
      this.setUser(null);
      localStorage.removeItem("user");
    },

    async updateAccessToken(): Promise<string | undefined> {
      const authToken = await authService.getToken();

      this.setUser({
        userId: this.authUser?.userId ?? "",
        emailVerified: this.authUser?.emailVerified ?? false,
        accessToken: authToken,
        userData: this.authUser?.userData ?? undefined,
      });

      return authToken;
    },

    async getUser(): Promise<UserAuth | null> {
      if (this.authUser) {
        return this.authUser;
      }

      const localUser = localStorage.getItem("user");

      if (localUser && localUser.length != 0) {
        const parsedUser = JSON.parse(localUser);
        this.setUser(parsedUser);
        return parsedUser;
      }
      return null;
    },
    persist: true,
  },
});
