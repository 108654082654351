<template>
  <v-card :width="calculateWidth" :loading="loadingLoginButton">
    <v-main fluid class="fill-height pa-4">
      <v-row>
        <v-col lg="7" md="12" class="d-flex align-center justify-center">
          <div class="w-100 mx-2">
            <div
              class="text-center text-h4 font-weight-bold"
              data-testid="test-login-title"
            >
              {{ t("oidc.loginWithPhone") }}
            </div>
            <v-card-text class="pt-10">
              <v-form v-model="valid" @submit.prevent>
                <v-phone-input
                  v-model="phone"
                  :country="country"
                  variant="outlined"
                  enable-searching-country
                  :disabled="loadingPhoneLogin"
                  :loading="loadingPhoneLogin"
                  :display-format="'international'"
                  :prefer-countries="['DE']"
                  @update:country="handleCountryChange"
                />

                <TheButton
                  :disabled="loadingPhoneLogin || !valid || phone.length === 0"
                  :loading="loadingPhoneLogin"
                  @clicked-button="sendMessage"
                  class="mt-2"
                  :title="t('oidc.send')"
                />
              </v-form>
            </v-card-text>

            <TheErrorBox class="ml-15 mt-2" :title="loginPhoneErrorMessage" />

            <TheMainText
              @click="changeFormView"
              :clickable="true"
              :disabled="loadingPhoneLogin"
              :title="t('oidc.loginWithEmail')"
            />
          </div>
        </v-col>
        <v-col v-if="!$vuetify.display.mdAndDown" cols="5">
          <div class="d-flex align-center justify-center image-container">
            <v-img
              :src="ConrooConnectIllustration"
              cover
              max-height="100%"
              max-width="100%"
            />
          </div>
        </v-col>
      </v-row>
    </v-main>
  </v-card>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { getErrorByType } from "@/utils/error-utils";
import ConrooConnectIllustration from "@/assets/main/conroo-illustration-connect.svg";

// Components
import TheButton from "@/components/TheButton.vue";
import TheMainText from "@/components/TheMainText.vue";
import TheErrorBox from "@/components/TheErrorBox.vue";
import { getViewModel } from "@/views/general/login-page/login-page-logic";
import firebase from "firebase/compat/app";
import { useDisplay } from "vuetify";
import { useProcess } from "@/composables/useProcess";

defineProps({
  loadingLoginButton: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const { name } = useDisplay();
const viewModel = getViewModel();
const valid = ref(false);

const emit = defineEmits(["change-view", "redirect", "code-sent"]);

// Local state
const phone = ref("");
const country = ref("");
const loadingPhoneLogin = ref(false);
const loginPhoneErrorMessage = ref("");
const appVerifier = ref<firebase.auth.RecaptchaVerifier | null>(null);
const confirmationResult = ref<firebase.auth.ConfirmationResult | null>(null);

// Methods
const { startProcess, stopProcess } = useProcess(
  loginPhoneErrorMessage,
  loadingPhoneLogin,
);

const sendMessage = async () => {
  if (loadingPhoneLogin.value) return;
  startProcess();
  try {
    const confirmation = await viewModel.loginWithPhone(
      phone.value,
      appVerifier.value!,
    );
    confirmationResult.value = confirmation;

    await viewModel.showMessageSentSnackbar();

    emit("code-sent", {
      confirmationResult: confirmation,
      phoneNumber: phone.value,
      recaptchaVerifier: appVerifier.value,
    });
  } catch (e) {
    loginPhoneErrorMessage.value = t(getErrorByType(e)) as string;
  } finally {
    stopProcess();
  }
};

const changeFormView = () => {
  emit("change-view", "email");
};

// Initialize ReCaptcha
const initReCaptcha = () => {
  const masterdiv = document.getElementById("recaptcha-container");
  if (masterdiv) {
    while (masterdiv.firstChild) {
      masterdiv.removeChild(masterdiv.firstChild);
    }
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      },
    );
    appVerifier.value = recaptchaVerifier;
  }
};

const handleCountryChange = (newCountry: string) => {
  if (newCountry !== country.value) {
    nextTick(() => {
      phone.value = "";
    });
  }
};

const calculateWidth = computed(() => {
  switch (name.value) {
    case "xl":
      return "50vw";
    case "lg":
      return "60vw";
    case "md":
      return "60vw";
    case "sm":
      return "80vw";
    case "xs":
      return "90vw";
    default:
      return "100vw";
  }
});

// Initialize ReCaptcha on mount
onMounted(() => {
  initReCaptcha();
});
</script>

<style lang="scss" scoped></style>
