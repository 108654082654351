import * as axios from "axios";
import { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { useAuthStore } from "@/store/useAuthStore";

const axiosApiInstance = axios.default.create({
  timeout: 10000,
  baseURL: import.meta.env.VITE_BASE_ROOT,
});

//Request interceptor for API CALLS
axiosApiInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const authStore = useAuthStore();
    const token = authStore.authUser?.accessToken;

    //TODO: last refreshed token, and then check on updating it or check if getting token everytime introduces a load
    if (config.data === undefined) config.data = null;
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }
    config.headers["charset"] = "utf-8";
    // TODO - temporary fix. When OIDC is a separate project we can remove this config.url conditional
    if (token) {
      //only add auth if we have a token
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      const authStore = useAuthStore();
      originalRequest._retry = true;
      const token = await authStore.updateAccessToken();
      axios.default.defaults.headers.common["Authorization"] =
        `Bearer ${token}`;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default axiosApiInstance;
