/* tslint:disable */
/* eslint-disable */
/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * The version of the OpenAPI document: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Acceptance restriction model
 * @export
 * @interface AcceptanceRestriction
 */
export interface AcceptanceRestriction {
    /**
     * Terminal name of the current deadline
     * @type {string}
     * @memberof AcceptanceRestriction
     */
    acceptanceRestrictionName?: AcceptanceRestrictionAcceptanceRestrictionNameEnum;
    /**
     * 
     * @type {string}
     * @memberof AcceptanceRestriction
     */
    restrictionStart?: string;
    /**
     * 
     * @type {string}
     * @memberof AcceptanceRestriction
     */
    restrictionEnd?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AcceptanceRestrictionAcceptanceRestrictionNameEnum {
    AcceptanceDeadline = 'acceptance_deadline',
    OneToOneDeadline = 'one_to_one_deadline'
}

/**
 * Object which has all possible terminal restrictions
 * @export
 * @interface AcceptanceRestrictions
 */
export interface AcceptanceRestrictions {
    /**
     * 
     * @type {Array<AcceptanceRestriction>}
     * @memberof AcceptanceRestrictions
     */
    oneToOneRestrictions?: Array<AcceptanceRestriction>;
}
/**
 * arrive at facility request
 * @export
 * @interface ArriveAtFacilityRequest
 */
export interface ArriveAtFacilityRequest {
    /**
     * 
     * @type {string}
     * @memberof ArriveAtFacilityRequest
     */
    deviceToken?: string;
    /**
     * 
     * @type {TerminalPositionQrCodeTO}
     * @memberof ArriveAtFacilityRequest
     */
    qrCodeData: TerminalPositionQrCodeTO;
    /**
     * 
     * @type {string}
     * @memberof ArriveAtFacilityRequest
     */
    deviceId: string;
}
/**
 * Response Body from Firebase
 * @export
 * @interface AuthDetails
 */
export interface AuthDetails {
    /**
     * 
     * @type {string}
     * @memberof AuthDetails
     */
    codeChallenge: string;
    /**
     * 
     * @type {string}
     * @memberof AuthDetails
     */
    codeChallengeMethod: string;
    /**
     * 
     * @type {string}
     * @memberof AuthDetails
     */
    refreshToken: string;
    /**
     * 
     * @type {number}
     * @memberof AuthDetails
     */
    expiresIn: number;
    /**
     * 
     * @type {string}
     * @memberof AuthDetails
     */
    redirectUri: string;
    /**
     * 
     * @type {string}
     * @memberof AuthDetails
     */
    idToken: string;
    /**
     * 
     * @type {string}
     * @memberof AuthDetails
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthDetails
     */
    nonce?: string;
}
/**
 * Delivery/Pickup Booking of Container
 * @export
 * @interface Booking
 */
export interface Booking {
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    bookingId: number;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    relatedBookingsIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    referenceNumber?: string;
    /**
     * Type of a Task
     * @type {string}
     * @memberof Booking
     */
    type: BookingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    forwarderName?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    module?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    parkingLot?: string;
    /**
     * state of a Booking
     * @type {string}
     * @memberof Booking
     */
    bookingState: BookingBookingStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    containerUpdatable: boolean;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    cancelledBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    counterReason?: BookingCounterReasonEnum;
    /**
     * 
     * @type {number}
     * @memberof Booking
     * @deprecated
     */
    terminalId: number;
    /**
     * 
     * @type {string}
     * @memberof Booking
     * @deprecated
     */
    terminalName: string;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    facilityId: number;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    facilityName: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    facilityType: BookingFacilityTypeEnum;
    /**
     * 
     * @type {Container}
     * @memberof Booking
     */
    container?: Container;
    /**
     * 
     * @type {TimeSlot}
     * @memberof Booking
     */
    timeSlot: TimeSlot;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    bookingTerminalStatus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    userRegisterNext?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    autoRegisterNext?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Booking
     */
    missingFields?: Array<BookingMissingFieldsEnum>;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    customerName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BookingTypeEnum {
    Pickup = 'pickup',
    Delivery = 'delivery'
}
/**
    * @export
    * @enum {string}
    */
export enum BookingBookingStateEnum {
    Active = 'active',
    TerminalReached = 'terminal_reached',
    RegistrationBlocked = 'registration_blocked',
    RegistrationRestricted = 'registration_restricted',
    Registered = 'registered',
    Transhipment = 'transhipment',
    Completed = 'completed',
    Cancelled = 'cancelled',
    CounterRequired = 'counter_required'
}
/**
    * @export
    * @enum {string}
    */
export enum BookingCounterReasonEnum {
    DangerousGoods = 'dangerous_goods',
    Toll = 'toll',
    Waste = 'waste',
    NotDeliveredYet = 'not_delivered_yet',
    Blocked = 'blocked',
    ContainerDataIncomplete = 'container_data_incomplete',
    ModuleClosed = 'module_closed',
    Error = 'error',
    ErrorOnUpdateLoadUnit = 'error_on_update_load_unit',
    LoadingUnitUnknown = 'loading_unit_unknown'
}
/**
    * @export
    * @enum {string}
    */
export enum BookingFacilityTypeEnum {
    TERMINAL = 'TERMINAL',
    ISPSTERMINAL = 'ISPS_TERMINAL',
    DEPOT = 'DEPOT'
}
/**
    * @export
    * @enum {string}
    */
export enum BookingMissingFieldsEnum {
    NetWeight = 'net_weight',
    Seals = 'seals',
    ContainerNumber = 'container_number'
}

/**
 * Object use to request a booking
 * @export
 * @interface BookingRequest
 */
export interface BookingRequest {
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    containerNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    relatedBookingsIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    licensePlate: string;
    /**
     * Type of a Task
     * @type {string}
     * @memberof BookingRequest
     */
    type: BookingRequestTypeEnum;
    /**
     * 
     * @type {TimeSlot}
     * @memberof BookingRequest
     */
    timeSlot: TimeSlot;
    /**
     * 
     * @type {number}
     * @memberof BookingRequest
     */
    terminalId: number;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    forwarder: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BookingRequestTypeEnum {
    Pickup = 'pickup',
    Delivery = 'delivery'
}

/**
 * completed Delivery/Pickup Booking of Container
 * @export
 * @interface CompletedBooking
 */
export interface CompletedBooking {
    /**
     * 
     * @type {number}
     * @memberof CompletedBooking
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CompletedBooking
     */
    relatedBookingsIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof CompletedBooking
     */
    referenceNumber?: string;
    /**
     * Type of a Task
     * @type {string}
     * @memberof CompletedBooking
     */
    type: CompletedBookingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CompletedBooking
     */
    completedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CompletedBooking
     * @deprecated
     */
    terminalId?: number;
    /**
     * 
     * @type {string}
     * @memberof CompletedBooking
     * @deprecated
     */
    terminalName?: string;
    /**
     * 
     * @type {number}
     * @memberof CompletedBooking
     */
    facilityId: number;
    /**
     * 
     * @type {string}
     * @memberof CompletedBooking
     */
    facilityName: string;
    /**
     * 
     * @type {string}
     * @memberof CompletedBooking
     */
    facilityType: CompletedBookingFacilityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CompletedBooking
     */
    containerNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CompletedBooking
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompletedBooking
     */
    customerName?: string;
    /**
     * 
     * @type {TimeSlot}
     * @memberof CompletedBooking
     */
    timeSlot: TimeSlot;
}

/**
    * @export
    * @enum {string}
    */
export enum CompletedBookingTypeEnum {
    Pickup = 'pickup',
    Delivery = 'delivery'
}
/**
    * @export
    * @enum {string}
    */
export enum CompletedBookingFacilityTypeEnum {
    TERMINAL = 'TERMINAL',
    ISPSTERMINAL = 'ISPS_TERMINAL',
    DEPOT = 'DEPOT'
}

/**
 * container / loading unit
 * @export
 * @interface Container
 */
export interface Container {
    /**
     * 
     * @type {number}
     * @memberof Container
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    containerNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    isoCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    suggestedContainerNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof Container
     */
    emptyWeight?: number;
    /**
     * 
     * @type {number}
     * @memberof Container
     */
    netWeight?: number;
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    containerType?: string;
    /**
     * 
     * @type {number}
     * @memberof Container
     */
    containerLength?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Container
     */
    hasDangerousGoods?: boolean;
    /**
     * 
     * @type {Array<DangerousGoodsData>}
     * @memberof Container
     */
    listDangerousGoodsData?: Array<DangerousGoodsData>;
    /**
     * 
     * @type {boolean}
     * @memberof Container
     */
    hasSeal?: boolean;
    /**
     * 
     * @type {Array<SealData>}
     * @memberof Container
     */
    listSealData?: Array<SealData>;
    /**
     * 
     * @type {boolean}
     * @memberof Container
     */
    hasToll?: boolean;
    /**
     * 
     * @type {Array<TollData>}
     * @memberof Container
     */
    listTollData?: Array<TollData>;
    /**
     * 
     * @type {boolean}
     * @memberof Container
     */
    hasWaste?: boolean;
    /**
     * 
     * @type {Array<WasteData>}
     * @memberof Container
     */
    listWasteData?: Array<WasteData>;
    /**
     * 
     * @type {number}
     * @memberof Container
     */
    overhang?: number;
    /**
     * 
     * @type {number}
     * @memberof Container
     */
    teu?: number;
}
/**
 * 
 * @export
 * @interface CustomerTO
 */
export interface CustomerTO {
    /**
     * 
     * @type {string}
     * @memberof CustomerTO
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTO
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerTO
     */
    avisMandatory?: boolean;
}
/**
 * DangerousGoodsData
 * @export
 * @interface DangerousGoodsData
 */
export interface DangerousGoodsData {
    /**
     * 
     * @type {number}
     * @memberof DangerousGoodsData
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DangerousGoodsData
     */
    lq: boolean;
    /**
     * 
     * @type {string}
     * @memberof DangerousGoodsData
     */
    uNNr?: string;
    /**
     * 
     * @type {string}
     * @memberof DangerousGoodsData
     */
    clazz: string;
    /**
     * 
     * @type {string}
     * @memberof DangerousGoodsData
     */
    addedBy: DangerousGoodsDataAddedByEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DangerousGoodsDataAddedByEnum {
    Trucker = 'trucker',
    Terminal = 'terminal',
    ForwarderOperator = 'forwarder_operator'
}

/**
 * 
 * @export
 * @interface DepotContainerBookingTO
 */
export interface DepotContainerBookingTO {
    /**
     * 
     * @type {number}
     * @memberof DepotContainerBookingTO
     */
    containerBookingId: number;
    /**
     * 
     * @type {Container}
     * @memberof DepotContainerBookingTO
     */
    container?: Container;
    /**
     * 
     * @type {string}
     * @memberof DepotContainerBookingTO
     */
    module?: string;
    /**
     * 
     * @type {string}
     * @memberof DepotContainerBookingTO
     */
    position?: string;
    /**
     * 
     * @type {string}
     * @memberof DepotContainerBookingTO
     */
    referenceNumber?: string;
    /**
     * Type of a Task
     * @type {string}
     * @memberof DepotContainerBookingTO
     */
    type: DepotContainerBookingTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DepotContainerBookingTO
     */
    customerId: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DepotContainerBookingTOTypeEnum {
    Pickup = 'pickup',
    Delivery = 'delivery'
}

/**
 * 
 * @export
 * @interface DepotGetStatusMultiRequestTO
 */
export interface DepotGetStatusMultiRequestTO {
    /**
     * 
     * @type {Array<DepotGetStatusRequestTO>}
     * @memberof DepotGetStatusMultiRequestTO
     */
    statusRequests: Array<DepotGetStatusRequestTO>;
}
/**
 * 
 * @export
 * @interface DepotGetStatusRequestTO
 */
export interface DepotGetStatusRequestTO {
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusRequestTO
     */
    referenceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusRequestTO
     */
    containerNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusRequestTO
     */
    isoCode?: string;
    /**
     * Type of a Task
     * @type {string}
     * @memberof DepotGetStatusRequestTO
     */
    type: DepotGetStatusRequestTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusRequestTO
     */
    customerId: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DepotGetStatusRequestTOTypeEnum {
    Pickup = 'pickup',
    Delivery = 'delivery'
}

/**
 * 
 * @export
 * @interface DepotGetStatusResponseTO
 */
export interface DepotGetStatusResponseTO {
    /**
     * 
     * @type {DepotGetStatusRequestTO}
     * @memberof DepotGetStatusResponseTO
     */
    getStatusRequest: DepotGetStatusRequestTO;
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusResponseTO
     */
    validFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusResponseTO
     */
    validTo?: string;
    /**
     * 
     * @type {Container}
     * @memberof DepotGetStatusResponseTO
     */
    container?: Container;
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusResponseTO
     */
    module?: string;
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusResponseTO
     */
    position?: string;
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusResponseTO
     */
    errorCode?: DepotGetStatusResponseTOErrorCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DepotGetStatusResponseTOErrorCodeEnum {
    REQUESTNOTVALID = 'REQUEST_NOT_VALID',
    CTRIDINVALID = 'CTR_ID_INVALID',
    REFERENCENUMBERMISSING = 'REFERENCE_NUMBER_MISSING',
    ISOCODEMISSING = 'ISO_CODE_MISSING',
    CUSTOMERKEYMISSING = 'CUSTOMER_KEY_MISSING',
    TURNINREFMISSING = 'TURN_IN_REF_MISSING',
    CTRIDMISSING = 'CTR_ID_MISSING',
    CTRIDANDISOCODEMISSING = 'CTR_ID_AND_ISO_CODE_MISSING',
    CTRIDANDISOCODESET = 'CTR_ID_AND_ISO_CODE_SET',
    CUSTOMERNOTFOUND = 'CUSTOMER_NOT_FOUND',
    DEPOTNOTFOUND = 'DEPOT_NOT_FOUND',
    AVISNOTFOUND = 'AVIS_NOT_FOUND',
    AVISNOTFORFACILITY = 'AVIS_NOT_FOR_FACILITY',
    AVISNOTFORCUSTOMER = 'AVIS_NOT_FOR_CUSTOMER',
    AVISNOTVALID = 'AVIS_NOT_VALID',
    AVISNOTFORCTRID = 'AVIS_NOT_FOR_CTRID',
    RELEASENOTFOUND = 'RELEASE_NOT_FOUND',
    RELEASENOTOPEN = 'RELEASE_NOT_OPEN',
    RELEASENOTFORFACILITY = 'RELEASE_NOT_FOR_FACILITY',
    RELEASENOTFORCUSTOMER = 'RELEASE_NOT_FOR_CUSTOMER',
    RELEASENOTVALID = 'RELEASE_NOT_VALID'
}

/**
 * The device token to be created
 * @export
 * @interface DeviceInformation
 */
export interface DeviceInformation {
    /**
     * 
     * @type {string}
     * @memberof DeviceInformation
     */
    deviceToken: string;
    /**
     * Operating System
     * @type {string}
     * @memberof DeviceInformation
     */
    operatingSystem?: DeviceInformationOperatingSystemEnum;
    /**
     * 
     * @type {string}
     * @memberof DeviceInformation
     */
    operatingSystemVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceInformation
     */
    currentAppVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceInformation
     */
    deviceModel?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceInformation
     */
    locationPermissions?: DeviceInformationLocationPermissionsEnum;
    /**
     * 
     * @type {string}
     * @memberof DeviceInformation
     */
    deviceId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DeviceInformationOperatingSystemEnum {
    Android = 'android',
    Ios = 'ios'
}
/**
    * @export
    * @enum {string}
    */
export enum DeviceInformationLocationPermissionsEnum {
    Granted = 'granted',
    GrantedLimited = 'grantedLimited',
    Denied = 'denied',
    DeniedForever = 'deniedForever'
}

/**
 * Error Object
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    message?: string;
}
/**
 * Facility
 * @export
 * @interface Facility
 */
export interface Facility {
    /**
     * 
     * @type {number}
     * @memberof Facility
     */
    facilityId: number;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    type: FacilityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    logoUrl: string;
    /**
     * 
     * @type {number}
     * @memberof Facility
     */
    latPosition: number;
    /**
     * 
     * @type {number}
     * @memberof Facility
     */
    lonPosition: number;
}

/**
    * @export
    * @enum {string}
    */
export enum FacilityTypeEnum {
    TERMINAL = 'TERMINAL',
    ISPSTERMINAL = 'ISPS_TERMINAL',
    DEPOT = 'DEPOT'
}

/**
 * Forwarder
 * @export
 * @interface Forwarder
 */
export interface Forwarder {
    /**
     * 
     * @type {number}
     * @memberof Forwarder
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Forwarder
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Forwarder
     */
    email: string;
}
/**
 * General terminal config object
 * @export
 * @interface GeneralTerminalConfig
 */
export interface GeneralTerminalConfig {
    /**
     * 
     * @type {Array<TerminalValues>}
     * @memberof GeneralTerminalConfig
     */
    terminalNumericValues?: Array<TerminalValues>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralTerminalConfig
     */
    terminalAttributes?: Array<GeneralTerminalConfigTerminalAttributesEnum>;
    /**
     * 
     * @type {AcceptanceRestrictions}
     * @memberof GeneralTerminalConfig
     */
    acceptanceRestrictions?: AcceptanceRestrictions;
}

/**
    * @export
    * @enum {string}
    */
export enum GeneralTerminalConfigTerminalAttributesEnum {
    Geofencing = 'geofencing',
    Videogate = 'videogate',
    RedSlotActive = 'red_slot_active',
    ContainerUpdateOnce = 'container_update_once',
    IsoContainerNumberValidation = 'iso_container_number_validation'
}

/**
 * 
 * @export
 * @interface GetStatusErrorTO
 */
export interface GetStatusErrorTO {
    /**
     * 
     * @type {string}
     * @memberof GetStatusErrorTO
     */
    error?: GetStatusErrorTOErrorEnum;
    /**
     * 
     * @type {DepotGetStatusRequestTO}
     * @memberof GetStatusErrorTO
     */
    requestData?: DepotGetStatusRequestTO;
}

/**
    * @export
    * @enum {string}
    */
export enum GetStatusErrorTOErrorEnum {
    REQUESTNOTVALID = 'REQUEST_NOT_VALID',
    CTRIDINVALID = 'CTR_ID_INVALID',
    REFERENCENUMBERMISSING = 'REFERENCE_NUMBER_MISSING',
    ISOCODEMISSING = 'ISO_CODE_MISSING',
    CUSTOMERKEYMISSING = 'CUSTOMER_KEY_MISSING',
    TURNINREFMISSING = 'TURN_IN_REF_MISSING',
    CTRIDMISSING = 'CTR_ID_MISSING',
    CTRIDANDISOCODEMISSING = 'CTR_ID_AND_ISO_CODE_MISSING',
    CTRIDANDISOCODESET = 'CTR_ID_AND_ISO_CODE_SET',
    CUSTOMERNOTFOUND = 'CUSTOMER_NOT_FOUND',
    DEPOTNOTFOUND = 'DEPOT_NOT_FOUND',
    AVISNOTFOUND = 'AVIS_NOT_FOUND',
    AVISNOTFORFACILITY = 'AVIS_NOT_FOR_FACILITY',
    AVISNOTFORCUSTOMER = 'AVIS_NOT_FOR_CUSTOMER',
    AVISNOTVALID = 'AVIS_NOT_VALID',
    AVISNOTFORCTRID = 'AVIS_NOT_FOR_CTRID',
    RELEASENOTFOUND = 'RELEASE_NOT_FOUND',
    RELEASENOTOPEN = 'RELEASE_NOT_OPEN',
    RELEASENOTFORFACILITY = 'RELEASE_NOT_FOR_FACILITY',
    RELEASENOTFORCUSTOMER = 'RELEASE_NOT_FOR_CUSTOMER',
    RELEASENOTVALID = 'RELEASE_NOT_VALID'
}

/**
 * Ref & leNumber as an StatusRequest object
 * @export
 * @interface GetStatusRequest
 */
export interface GetStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof GetStatusRequest
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRequest
     */
    containerNumber?: string;
    /**
     * Type of a Task
     * @type {string}
     * @memberof GetStatusRequest
     */
    type: GetStatusRequestTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof GetStatusRequest
     */
    terminalId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum GetStatusRequestTypeEnum {
    Pickup = 'pickup',
    Delivery = 'delivery'
}

/**
 * GetStatus Response
 * @export
 * @interface GetStatusResponse
 */
export interface GetStatusResponse {
    /**
     * 
     * @type {Container}
     * @memberof GetStatusResponse
     */
    container?: Container;
    /**
     * 
     * @type {Array<TimeSlot>}
     * @memberof GetStatusResponse
     */
    timeSlots?: Array<TimeSlot>;
    /**
     * 
     * @type {string}
     * @memberof GetStatusResponse
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusResponse
     */
    bookingTerminalStatus?: string;
}
/**
 * IDNOWLinkResponse
 * @export
 * @interface IDNOWLinkResponse
 */
export interface IDNOWLinkResponse {
    /**
     * 
     * @type {string}
     * @memberof IDNOWLinkResponse
     */
    link?: string;
}
/**
 * 
 * @export
 * @interface IDNOWStartIdentRequestTO
 */
export interface IDNOWStartIdentRequestTO {
    /**
     * 
     * @type {string}
     * @memberof IDNOWStartIdentRequestTO
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof IDNOWStartIdentRequestTO
     */
    deviceId?: string;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * apk file for the new version
     * @type {any}
     * @memberof InlineObject
     */
    file: any;
}
/**
 * License Key for validation
 * @export
 * @interface IspsLicenseKeyRequestTO
 */
export interface IspsLicenseKeyRequestTO {
    /**
     * 
     * @type {string}
     * @memberof IspsLicenseKeyRequestTO
     */
    truckerId: string;
    /**
     * 
     * @type {string}
     * @memberof IspsLicenseKeyRequestTO
     */
    licenseKey: string;
}
/**
 * 
 * @export
 * @interface IspsLicenseKeyResponseTO
 */
export interface IspsLicenseKeyResponseTO {
    /**
     * 
     * @type {boolean}
     * @memberof IspsLicenseKeyResponseTO
     */
    active?: boolean;
}
/**
 * 
 * @export
 * @interface LocalTime
 */
export interface LocalTime {
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    hour?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    minute?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    second?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    nano?: number;
}
/**
 * Location Point
 * @export
 * @interface LocationPoint
 */
export interface LocationPoint {
    /**
     * 
     * @type {number}
     * @memberof LocationPoint
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof LocationPoint
     */
    lon: number;
}
/**
 * Mobile App Version validation response
 * @export
 * @interface MobileAppVersionValidationResponse
 */
export interface MobileAppVersionValidationResponse {
    /**
     * App Version State
     * @type {string}
     * @memberof MobileAppVersionValidationResponse
     */
    mobileAppVersionState?: MobileAppVersionValidationResponseMobileAppVersionStateEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MobileAppVersionValidationResponseMobileAppVersionStateEnum {
    Required = 'required',
    Optional = 'optional',
    None = 'none'
}

/**
 * 
 * @export
 * @interface MobileDepotBookingCreationRequestTO
 */
export interface MobileDepotBookingCreationRequestTO {
    /**
     * 
     * @type {string}
     * @memberof MobileDepotBookingCreationRequestTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof MobileDepotBookingCreationRequestTO
     */
    containerNumber?: string;
    /**
     * Type of a Task
     * @type {string}
     * @memberof MobileDepotBookingCreationRequestTO
     */
    type: MobileDepotBookingCreationRequestTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MobileDepotBookingCreationRequestTO
     */
    isoCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileDepotBookingCreationRequestTO
     */
    customerId: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MobileDepotBookingCreationRequestTOTypeEnum {
    Pickup = 'pickup',
    Delivery = 'delivery'
}

/**
 * 
 * @export
 * @interface MobileTourCreationRequestTO
 */
export interface MobileTourCreationRequestTO {
    /**
     * 
     * @type {TimeSlot}
     * @memberof MobileTourCreationRequestTO
     */
    timeSlot: TimeSlot;
    /**
     * 
     * @type {Array<MobileDepotBookingCreationRequestTO>}
     * @memberof MobileTourCreationRequestTO
     */
    statusRequests: Array<MobileDepotBookingCreationRequestTO>;
}
/**
 * 
 * @export
 * @interface MultiStatusGetResponseTO
 */
export interface MultiStatusGetResponseTO {
    /**
     * 
     * @type {MultiStatusSuccessTO}
     * @memberof MultiStatusGetResponseTO
     */
    data?: MultiStatusSuccessTO;
    /**
     * 
     * @type {Array<GetStatusErrorTO>}
     * @memberof MultiStatusGetResponseTO
     */
    errors?: Array<GetStatusErrorTO>;
    /**
     * 
     * @type {boolean}
     * @memberof MultiStatusGetResponseTO
     */
    hasError?: boolean;
}
/**
 * 
 * @export
 * @interface MultiStatusSuccessTO
 */
export interface MultiStatusSuccessTO {
    /**
     * 
     * @type {Array<DepotGetStatusResponseTO>}
     * @memberof MultiStatusSuccessTO
     */
    responses?: Array<DepotGetStatusResponseTO>;
    /**
     * 
     * @type {Array<TimeSlot>}
     * @memberof MultiStatusSuccessTO
     */
    timeSlots?: Array<TimeSlot>;
}
/**
 * Reset password body
 * @export
 * @interface ResetPassword
 */
export interface ResetPassword {
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    email: string;
}
/**
 * SealData
 * @export
 * @interface SealData
 */
export interface SealData {
    /**
     * 
     * @type {number}
     * @memberof SealData
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SealData
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof SealData
     */
    addedBy: SealDataAddedByEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SealDataAddedByEnum {
    Trucker = 'trucker',
    Terminal = 'terminal',
    ForwarderOperator = 'forwarder_operator'
}

/**
 * select bookings request object containing bookingIds to be registered next
 * @export
 * @interface SelectBookingsRequest
 */
export interface SelectBookingsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof SelectBookingsRequest
     */
    bookingIds: Array<number>;
}
/**
 * Terminal
 * @export
 * @interface Terminal
 */
export interface Terminal {
    /**
     * 
     * @type {number}
     * @memberof Terminal
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Terminal
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Terminal
     */
    logoUrl: string;
    /**
     * 
     * @type {number}
     * @memberof Terminal
     */
    latPosition: number;
    /**
     * 
     * @type {number}
     * @memberof Terminal
     */
    lonPosition: number;
    /**
     * 
     * @type {Array<TerminalModule>}
     * @memberof Terminal
     */
    modules?: Array<TerminalModule>;
    /**
     * 
     * @type {Array<TerminalOpeningHour>}
     * @memberof Terminal
     */
    openingHours?: Array<TerminalOpeningHour>;
}
/**
 * Terminal module
 * @export
 * @interface TerminalModule
 */
export interface TerminalModule {
    /**
     * 
     * @type {string}
     * @memberof TerminalModule
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TerminalModule
     */
    moduleName: string;
    /**
     * 
     * @type {boolean}
     * @memberof TerminalModule
     */
    opened?: boolean;
}
/**
 * Terminal opening hour
 * @export
 * @interface TerminalOpeningHour
 */
export interface TerminalOpeningHour {
    /**
     * 
     * @type {number}
     * @memberof TerminalOpeningHour
     */
    id: number;
    /**
     * 
     * @type {LocalTime}
     * @memberof TerminalOpeningHour
     */
    openedFrom: LocalTime;
    /**
     * 
     * @type {LocalTime}
     * @memberof TerminalOpeningHour
     */
    closedFrom: LocalTime;
    /**
     * 
     * @type {string}
     * @memberof TerminalOpeningHour
     */
    openingHourDay: TerminalOpeningHourOpeningHourDayEnum;
    /**
     * type of Facility opening hour
     * @type {string}
     * @memberof TerminalOpeningHour
     */
    openingHourType: TerminalOpeningHourOpeningHourTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TerminalOpeningHourOpeningHourDayEnum {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY'
}
/**
    * @export
    * @enum {string}
    */
export enum TerminalOpeningHourOpeningHourTypeEnum {
    Pickup = 'pickup',
    Delivery = 'delivery',
    Counter = 'counter'
}

/**
 * terminal qr code data
 * @export
 * @interface TerminalPositionQrCodeTO
 */
export interface TerminalPositionQrCodeTO {
    /**
     * 
     * @type {number}
     * @memberof TerminalPositionQrCodeTO
     */
    terminalId: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalPositionQrCodeTO
     */
    positionId: number;
    /**
     * 
     * @type {string}
     * @memberof TerminalPositionQrCodeTO
     */
    key: string;
}
/**
 * Terminal reduced object
 * @export
 * @interface TerminalReduced
 */
export interface TerminalReduced {
    /**
     * 
     * @type {number}
     * @memberof TerminalReduced
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TerminalReduced
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TerminalReduced
     */
    logoUrl: string;
    /**
     * 
     * @type {number}
     * @memberof TerminalReduced
     */
    latPosition: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalReduced
     */
    lonPosition: number;
}
/**
 * terminal values
 * @export
 * @interface TerminalValues
 */
export interface TerminalValues {
    /**
     * terminal numeric attributes
     * @type {string}
     * @memberof TerminalValues
     */
    valueName?: TerminalValuesValueNameEnum;
    /**
     * Unit
     * @type {string}
     * @memberof TerminalValues
     */
    unit?: TerminalValuesUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof TerminalValues
     */
    value?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TerminalValuesValueNameEnum {
    Delivery = 'container_length_register_limit_delivery',
    Pickup = 'container_length_register_limit_pickup'
}
/**
    * @export
    * @enum {string}
    */
export enum TerminalValuesUnitEnum {
    Kg = 'kg',
    Feet = 'feet',
    Ton = 'ton',
    Meter = 'meter',
    Text = 'text'
}

/**
 * timeslot to be changed to
 * @export
 * @interface TimeSlot
 */
export interface TimeSlot {
    /**
     * 
     * @type {number}
     * @memberof TimeSlot
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TimeSlot
     */
    slotEnd: string;
    /**
     * 
     * @type {string}
     * @memberof TimeSlot
     */
    slotBegin: string;
    /**
     * category of a time slot
     * @type {string}
     * @memberof TimeSlot
     */
    category?: TimeSlotCategoryEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TimeSlotCategoryEnum {
    Green = 'green',
    Yellow = 'yellow',
    Red = 'red',
    NotSet = 'not_set'
}

/**
 * TollData
 * @export
 * @interface TollData
 */
export interface TollData {
    /**
     * 
     * @type {number}
     * @memberof TollData
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TollData
     */
    documentType: string;
    /**
     * 
     * @type {string}
     * @memberof TollData
     */
    documentNumber: string;
    /**
     * 
     * @type {string}
     * @memberof TollData
     */
    addedBy: TollDataAddedByEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TollDataAddedByEnum {
    Trucker = 'trucker',
    Terminal = 'terminal',
    ForwarderOperator = 'forwarder_operator'
}

/**
 * 
 * @export
 * @interface TourTO
 */
export interface TourTO {
    /**
     * 
     * @type {number}
     * @memberof TourTO
     */
    tourId?: number;
    /**
     * 
     * @type {Array<DepotContainerBookingTO>}
     * @memberof TourTO
     */
    containerBookings: Array<DepotContainerBookingTO>;
}
/**
 * response for registering truck arrival at terminal
 * @export
 * @interface TruckArrivalResponseTO
 */
export interface TruckArrivalResponseTO {
    /**
     * 
     * @type {string}
     * @memberof TruckArrivalResponseTO
     */
    visitId?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckArrivalResponseTO
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckArrivalResponseTO
     */
    errorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckArrivalResponseTO
     */
    stage?: string;
    /**
     * Differentiates between info and error messages
     * @type {string}
     * @memberof TruckArrivalResponseTO
     */
    messageType?: TruckArrivalResponseTOMessageTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TruckArrivalResponseTOMessageTypeEnum {
    Info = 'info',
    Error = 'error'
}

/**
 * Created user object
 * @export
 * @interface Trucker
 */
export interface Trucker {
    /**
     * 
     * @type {string}
     * @memberof Trucker
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof Trucker
     */
    authType?: TruckerAuthTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Trucker
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Trucker
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof Trucker
     */
    locale?: string;
    /**
     * 
     * @type {string}
     * @memberof Trucker
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Trucker
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Trucker
     */
    phoneNumber?: string;
    /**
     * 
     * @type {Forwarder}
     * @memberof Trucker
     */
    forwarder: Forwarder;
    /**
     * 
     * @type {string}
     * @memberof Trucker
     */
    otherForwarder?: string;
    /**
     * 
     * @type {string}
     * @memberof Trucker
     */
    licensePlate?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TruckerAuthTypeEnum {
    Email = 'email',
    PhoneNumber = 'phone_number'
}

/**
 * refNumber and Container object
 * @export
 * @interface UpdateLoadUnitRequest
 */
export interface UpdateLoadUnitRequest {
    /**
     * 
     * @type {Container}
     * @memberof UpdateLoadUnitRequest
     */
    container: Container;
    /**
     * 
     * @type {string}
     * @memberof UpdateLoadUnitRequest
     */
    referenceNumber: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoadUnitRequest
     */
    statusQueryId?: number;
}
/**
 * Created user object
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    locale?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phoneNumber?: string;
    /**
     * 
     * @type {Forwarder}
     * @memberof User
     */
    forwarder: Forwarder;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    otherForwarder?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    licensePlate?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    isAtTerminal?: number;
    /**
     * 
     * @type {IspsLicenseKeyResponseTO}
     * @memberof User
     */
    ispsLicense?: IspsLicenseKeyResponseTO;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    identityVerified?: UserIdentityVerifiedEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    deviceVerified?: UserDeviceVerifiedEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UserIdentityVerifiedEnum {
    VERIFIED = 'VERIFIED',
    NOTVERIFIED = 'NOT_VERIFIED',
    PENDING = 'PENDING',
    DENIED = 'DENIED'
}
/**
    * @export
    * @enum {string}
    */
export enum UserDeviceVerifiedEnum {
    VERIFIED = 'VERIFIED',
    NOTVERIFIED = 'NOT_VERIFIED'
}

/**
 * Current Location
 * @export
 * @interface UserLocation
 */
export interface UserLocation {
    /**
     * 
     * @type {LocationPoint}
     * @memberof UserLocation
     */
    point: LocationPoint;
    /**
     * 
     * @type {number}
     * @memberof UserLocation
     */
    accuracy?: number;
}
/**
 * nbs confirmation
 * @export
 * @interface UserNbsConfirmation
 */
export interface UserNbsConfirmation {
    /**
     * 
     * @type {string}
     * @memberof UserNbsConfirmation
     */
    version: string;
    /**
     * 
     * @type {string}
     * @memberof UserNbsConfirmation
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof UserNbsConfirmation
     */
    acceptedDate?: string;
}
/**
 * tnc confirmation
 * @export
 * @interface UserTncConfirmation
 */
export interface UserTncConfirmation {
    /**
     * 
     * @type {string}
     * @memberof UserTncConfirmation
     */
    version: string;
    /**
     * 
     * @type {string}
     * @memberof UserTncConfirmation
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof UserTncConfirmation
     */
    acceptedDate?: string;
}
/**
 * value component
 * @export
 * @interface ValueComponent
 */
export interface ValueComponent {
    /**
     * Terminal Value Field Name in UI
     * @type {string}
     * @memberof ValueComponent
     */
    fieldName?: ValueComponentFieldNameEnum;
    /**
     * Unit
     * @type {string}
     * @memberof ValueComponent
     */
    unit?: ValueComponentUnitEnum;
    /**
     * Terminal Value Field property
     * @type {string}
     * @memberof ValueComponent
     */
    terminalValueFieldProperty?: ValueComponentTerminalValueFieldPropertyEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ValueComponentFieldNameEnum {
    ContainerNumber = 'container_number',
    ContainerLength = 'container_length',
    Seal = 'seal',
    ContainerWeight = 'container_weight'
}
/**
    * @export
    * @enum {string}
    */
export enum ValueComponentUnitEnum {
    Kg = 'kg',
    Feet = 'feet',
    Ton = 'ton',
    Meter = 'meter',
    Text = 'text'
}
/**
    * @export
    * @enum {string}
    */
export enum ValueComponentTerminalValueFieldPropertyEnum {
    Optional = 'optional',
    Required = 'required',
    AlwaysNull = 'always_null'
}

/**
 * verify email body
 * @export
 * @interface VerifyEmail
 */
export interface VerifyEmail {
    /**
     * 
     * @type {string}
     * @memberof VerifyEmail
     */
    email: string;
}
/**
 * view component
 * @export
 * @interface ViewComponent
 */
export interface ViewComponent {
    /**
     * view component Name in UI
     * @type {string}
     * @memberof ViewComponent
     */
    componentName?: ViewComponentComponentNameEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ViewComponentComponentNameEnum {
    DoubleBookingBox = 'double_booking_box'
}

/**
 * view config
 * @export
 * @interface ViewConfig
 */
export interface ViewConfig {
    /**
     * View name
     * @type {string}
     * @memberof ViewConfig
     */
    viewName?: ViewConfigViewNameEnum;
    /**
     * 
     * @type {number}
     * @memberof ViewConfig
     */
    terminalId?: number;
    /**
     * 
     * @type {Array<ValueComponent>}
     * @memberof ViewConfig
     */
    valueComponents?: Array<ValueComponent>;
    /**
     * 
     * @type {Array<ViewComponent>}
     * @memberof ViewConfig
     */
    viewComponents?: Array<ViewComponent>;
}

/**
    * @export
    * @enum {string}
    */
export enum ViewConfigViewNameEnum {
    UpdateLoadUnit = 'update_load_unit'
}

/**
 * WasteData
 * @export
 * @interface WasteData
 */
export interface WasteData {
    /**
     * 
     * @type {number}
     * @memberof WasteData
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WasteData
     */
    eAKKeyNumber: string;
    /**
     * 
     * @type {string}
     * @memberof WasteData
     */
    aVVNumber: string;
    /**
     * 
     * @type {string}
     * @memberof WasteData
     */
    voucherNumber: string;
    /**
     * 
     * @type {string}
     * @memberof WasteData
     */
    addedBy: WasteDataAddedByEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum WasteDataAddedByEnum {
    Trucker = 'trucker',
    Terminal = 'terminal',
    ForwarderOperator = 'forwarder_operator'
}


/**
 * BookingsApi - axios parameter creator
 * @export
 */
export const BookingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancels a booking
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBooking: async (userId: string, bookingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling cancelBooking.');
            }
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError('bookingId','Required parameter bookingId was null or undefined when calling cancelBooking.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/bookings/{bookingId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancels a booking with all dependencies
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBookingWithDependencies: async (userId: string, bookingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling cancelBookingWithDependencies.');
            }
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError('bookingId','Required parameter bookingId was null or undefined when calling cancelBookingWithDependencies.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/bookings/{bookingId}/dependencies`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Performs a booking
         * @param {string} userId UUID that comes from firebase authentication
         * @param {BookingRequest} bookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBooking: async (userId: string, bookingRequest: BookingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling createBooking.');
            }
            // verify required parameter 'bookingRequest' is not null or undefined
            if (bookingRequest === null || bookingRequest === undefined) {
                throw new RequiredError('bookingRequest','Required parameter bookingRequest was null or undefined when calling createBooking.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/bookings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof bookingRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bookingRequest !== undefined ? bookingRequest : {}) : (bookingRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Booking by id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingById: async (userId: string, bookingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getBookingById.');
            }
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError('bookingId','Required parameter bookingId was null or undefined when calling getBookingById.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/bookings/{bookingId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all available time slots for the given booking.
         * @summary List available time slots for booking.
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookingTimeslots: async (userId: string, bookingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling listBookingTimeslots.');
            }
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError('bookingId','Required parameter bookingId was null or undefined when calling listBookingTimeslots.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/bookings/{bookingId}/timeslots`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all Bookings
         * @param {string} userId UUID that comes from firebase authentication
         * @param {boolean} active boolean whether active or non active tasks should be supplied, if not set, all tasks are supplied
         * @param {number} [page] page to be shown
         * @param {number} [size] page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookings: async (userId: string, active: boolean, page?: number, size?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling listBookings.');
            }
            // verify required parameter 'active' is not null or undefined
            if (active === null || active === undefined) {
                throw new RequiredError('active','Required parameter active was null or undefined when calling listBookings.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/bookings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all completed Bookings with reduced objects
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} page page to be shown
         * @param {number} size page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookingsCompleted: async (userId: string, page: number, size: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling listBookingsCompleted.');
            }
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling listBookingsCompleted.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling listBookingsCompleted.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/bookings/completed`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * select bookings to be registered on next terminal arrival
         * @summary select bookings to be registered next
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {SelectBookingsRequest} selectBookingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectBookings: async (userId: string, terminalId: number, selectBookingsRequest: SelectBookingsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling selectBookings.');
            }
            // verify required parameter 'terminalId' is not null or undefined
            if (terminalId === null || terminalId === undefined) {
                throw new RequiredError('terminalId','Required parameter terminalId was null or undefined when calling selectBookings.');
            }
            // verify required parameter 'selectBookingsRequest' is not null or undefined
            if (selectBookingsRequest === null || selectBookingsRequest === undefined) {
                throw new RequiredError('selectBookingsRequest','Required parameter selectBookingsRequest was null or undefined when calling selectBookings.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/terminals/{terminalId}/bookings/registerNext`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof selectBookingsRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(selectBookingsRequest !== undefined ? selectBookingsRequest : {}) : (selectBookingsRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * updates properties of a loading unit.
         * @summary updates a loading unit
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {UpdateLoadUnitRequest} updateLoadUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoadUnit: async (userId: string, bookingId: number, updateLoadUnitRequest: UpdateLoadUnitRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateLoadUnit.');
            }
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError('bookingId','Required parameter bookingId was null or undefined when calling updateLoadUnit.');
            }
            // verify required parameter 'updateLoadUnitRequest' is not null or undefined
            if (updateLoadUnitRequest === null || updateLoadUnitRequest === undefined) {
                throw new RequiredError('updateLoadUnitRequest','Required parameter updateLoadUnitRequest was null or undefined when calling updateLoadUnit.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/bookings/{bookingId}/containers`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateLoadUnitRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateLoadUnitRequest !== undefined ? updateLoadUnitRequest : {}) : (updateLoadUnitRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a timeslot for a booking
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {TimeSlot} timeSlot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeSlotForBooking: async (userId: string, bookingId: number, timeSlot: TimeSlot, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateTimeSlotForBooking.');
            }
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError('bookingId','Required parameter bookingId was null or undefined when calling updateTimeSlotForBooking.');
            }
            // verify required parameter 'timeSlot' is not null or undefined
            if (timeSlot === null || timeSlot === undefined) {
                throw new RequiredError('timeSlot','Required parameter timeSlot was null or undefined when calling updateTimeSlotForBooking.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/bookings/{bookingId}/timeslots`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof timeSlot !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(timeSlot !== undefined ? timeSlot : {}) : (timeSlot || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingsApi - functional programming interface
 * @export
 */
export const BookingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancels a booking
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelBooking(userId: string, bookingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BookingsApiAxiosParamCreator(configuration).cancelBooking(userId, bookingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Cancels a booking with all dependencies
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelBookingWithDependencies(userId: string, bookingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BookingsApiAxiosParamCreator(configuration).cancelBookingWithDependencies(userId, bookingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Performs a booking
         * @param {string} userId UUID that comes from firebase authentication
         * @param {BookingRequest} bookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBooking(userId: string, bookingRequest: BookingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await BookingsApiAxiosParamCreator(configuration).createBooking(userId, bookingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Booking by id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingById(userId: string, bookingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await BookingsApiAxiosParamCreator(configuration).getBookingById(userId, bookingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns all available time slots for the given booking.
         * @summary List available time slots for booking.
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBookingTimeslots(userId: string, bookingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeSlot>>> {
            const localVarAxiosArgs = await BookingsApiAxiosParamCreator(configuration).listBookingTimeslots(userId, bookingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all Bookings
         * @param {string} userId UUID that comes from firebase authentication
         * @param {boolean} active boolean whether active or non active tasks should be supplied, if not set, all tasks are supplied
         * @param {number} [page] page to be shown
         * @param {number} [size] page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBookings(userId: string, active: boolean, page?: number, size?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Booking>>> {
            const localVarAxiosArgs = await BookingsApiAxiosParamCreator(configuration).listBookings(userId, active, page, size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all completed Bookings with reduced objects
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} page page to be shown
         * @param {number} size page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBookingsCompleted(userId: string, page: number, size: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompletedBooking>>> {
            const localVarAxiosArgs = await BookingsApiAxiosParamCreator(configuration).listBookingsCompleted(userId, page, size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * select bookings to be registered on next terminal arrival
         * @summary select bookings to be registered next
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {SelectBookingsRequest} selectBookingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectBookings(userId: string, terminalId: number, selectBookingsRequest: SelectBookingsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BookingsApiAxiosParamCreator(configuration).selectBookings(userId, terminalId, selectBookingsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * updates properties of a loading unit.
         * @summary updates a loading unit
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {UpdateLoadUnitRequest} updateLoadUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLoadUnit(userId: string, bookingId: number, updateLoadUnitRequest: UpdateLoadUnitRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BookingsApiAxiosParamCreator(configuration).updateLoadUnit(userId, bookingId, updateLoadUnitRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates a timeslot for a booking
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {TimeSlot} timeSlot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTimeSlotForBooking(userId: string, bookingId: number, timeSlot: TimeSlot, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await BookingsApiAxiosParamCreator(configuration).updateTimeSlotForBooking(userId, bookingId, timeSlot, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BookingsApi - factory interface
 * @export
 */
export const BookingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Cancels a booking
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBooking(userId: string, bookingId: number, options?: any): AxiosPromise<void> {
            return BookingsApiFp(configuration).cancelBooking(userId, bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancels a booking with all dependencies
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBookingWithDependencies(userId: string, bookingId: number, options?: any): AxiosPromise<void> {
            return BookingsApiFp(configuration).cancelBookingWithDependencies(userId, bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Performs a booking
         * @param {string} userId UUID that comes from firebase authentication
         * @param {BookingRequest} bookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBooking(userId: string, bookingRequest: BookingRequest, options?: any): AxiosPromise<Booking> {
            return BookingsApiFp(configuration).createBooking(userId, bookingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Booking by id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingById(userId: string, bookingId: number, options?: any): AxiosPromise<Booking> {
            return BookingsApiFp(configuration).getBookingById(userId, bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all available time slots for the given booking.
         * @summary List available time slots for booking.
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookingTimeslots(userId: string, bookingId: number, options?: any): AxiosPromise<Array<TimeSlot>> {
            return BookingsApiFp(configuration).listBookingTimeslots(userId, bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all Bookings
         * @param {string} userId UUID that comes from firebase authentication
         * @param {boolean} active boolean whether active or non active tasks should be supplied, if not set, all tasks are supplied
         * @param {number} [page] page to be shown
         * @param {number} [size] page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookings(userId: string, active: boolean, page?: number, size?: number, options?: any): AxiosPromise<Array<Booking>> {
            return BookingsApiFp(configuration).listBookings(userId, active, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all completed Bookings with reduced objects
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} page page to be shown
         * @param {number} size page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookingsCompleted(userId: string, page: number, size: number, options?: any): AxiosPromise<Array<CompletedBooking>> {
            return BookingsApiFp(configuration).listBookingsCompleted(userId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * select bookings to be registered on next terminal arrival
         * @summary select bookings to be registered next
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {SelectBookingsRequest} selectBookingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectBookings(userId: string, terminalId: number, selectBookingsRequest: SelectBookingsRequest, options?: any): AxiosPromise<void> {
            return BookingsApiFp(configuration).selectBookings(userId, terminalId, selectBookingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * updates properties of a loading unit.
         * @summary updates a loading unit
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {UpdateLoadUnitRequest} updateLoadUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoadUnit(userId: string, bookingId: number, updateLoadUnitRequest: UpdateLoadUnitRequest, options?: any): AxiosPromise<void> {
            return BookingsApiFp(configuration).updateLoadUnit(userId, bookingId, updateLoadUnitRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a timeslot for a booking
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} bookingId Booking id
         * @param {TimeSlot} timeSlot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeSlotForBooking(userId: string, bookingId: number, timeSlot: TimeSlot, options?: any): AxiosPromise<Booking> {
            return BookingsApiFp(configuration).updateTimeSlotForBooking(userId, bookingId, timeSlot, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingsApi - object-oriented interface
 * @export
 * @class BookingsApi
 * @extends {BaseAPI}
 */
export class BookingsApi extends BaseAPI {
    /**
     * 
     * @summary Cancels a booking
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} bookingId Booking id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public cancelBooking(userId: string, bookingId: number, options?: any) {
        return BookingsApiFp(this.configuration).cancelBooking(userId, bookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancels a booking with all dependencies
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} bookingId Booking id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public cancelBookingWithDependencies(userId: string, bookingId: number, options?: any) {
        return BookingsApiFp(this.configuration).cancelBookingWithDependencies(userId, bookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Performs a booking
     * @param {string} userId UUID that comes from firebase authentication
     * @param {BookingRequest} bookingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public createBooking(userId: string, bookingRequest: BookingRequest, options?: any) {
        return BookingsApiFp(this.configuration).createBooking(userId, bookingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Booking by id
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} bookingId Booking id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public getBookingById(userId: string, bookingId: number, options?: any) {
        return BookingsApiFp(this.configuration).getBookingById(userId, bookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all available time slots for the given booking.
     * @summary List available time slots for booking.
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} bookingId Booking id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public listBookingTimeslots(userId: string, bookingId: number, options?: any) {
        return BookingsApiFp(this.configuration).listBookingTimeslots(userId, bookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all Bookings
     * @param {string} userId UUID that comes from firebase authentication
     * @param {boolean} active boolean whether active or non active tasks should be supplied, if not set, all tasks are supplied
     * @param {number} [page] page to be shown
     * @param {number} [size] page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public listBookings(userId: string, active: boolean, page?: number, size?: number, options?: any) {
        return BookingsApiFp(this.configuration).listBookings(userId, active, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all completed Bookings with reduced objects
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} page page to be shown
     * @param {number} size page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public listBookingsCompleted(userId: string, page: number, size: number, options?: any) {
        return BookingsApiFp(this.configuration).listBookingsCompleted(userId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * select bookings to be registered on next terminal arrival
     * @summary select bookings to be registered next
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of the terminal
     * @param {SelectBookingsRequest} selectBookingsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public selectBookings(userId: string, terminalId: number, selectBookingsRequest: SelectBookingsRequest, options?: any) {
        return BookingsApiFp(this.configuration).selectBookings(userId, terminalId, selectBookingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * updates properties of a loading unit.
     * @summary updates a loading unit
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} bookingId Booking id
     * @param {UpdateLoadUnitRequest} updateLoadUnitRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public updateLoadUnit(userId: string, bookingId: number, updateLoadUnitRequest: UpdateLoadUnitRequest, options?: any) {
        return BookingsApiFp(this.configuration).updateLoadUnit(userId, bookingId, updateLoadUnitRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a timeslot for a booking
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} bookingId Booking id
     * @param {TimeSlot} timeSlot 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public updateTimeSlotForBooking(userId: string, bookingId: number, timeSlot: TimeSlot, options?: any) {
        return BookingsApiFp(this.configuration).updateTimeSlotForBooking(userId, bookingId, timeSlot, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get terminal configs
         * @summary Get terminal configs
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalConfig: async (userId: string, terminalId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getTerminalConfig.');
            }
            // verify required parameter 'terminalId' is not null or undefined
            if (terminalId === null || terminalId === undefined) {
                throw new RequiredError('terminalId','Required parameter terminalId was null or undefined when calling getTerminalConfig.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/terminals/{terminalId}/configs`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets view config
         * @summary Get View Components
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {'update_load_unit'} viewName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewConfig: async (userId: string, terminalId: number, viewName: 'update_load_unit', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getViewConfig.');
            }
            // verify required parameter 'terminalId' is not null or undefined
            if (terminalId === null || terminalId === undefined) {
                throw new RequiredError('terminalId','Required parameter terminalId was null or undefined when calling getViewConfig.');
            }
            // verify required parameter 'viewName' is not null or undefined
            if (viewName === null || viewName === undefined) {
                throw new RequiredError('viewName','Required parameter viewName was null or undefined when calling getViewConfig.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/terminals/{terminalId}/views`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (viewName !== undefined) {
                localVarQueryParameter['viewName'] = viewName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get terminal configs
         * @summary Get terminal configs
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerminalConfig(userId: string, terminalId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralTerminalConfig>> {
            const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).getTerminalConfig(userId, terminalId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Gets view config
         * @summary Get View Components
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {'update_load_unit'} viewName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getViewConfig(userId: string, terminalId: number, viewName: 'update_load_unit', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewConfig>> {
            const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).getViewConfig(userId, terminalId, viewName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get terminal configs
         * @summary Get terminal configs
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalConfig(userId: string, terminalId: number, options?: any): AxiosPromise<GeneralTerminalConfig> {
            return ConfigApiFp(configuration).getTerminalConfig(userId, terminalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets view config
         * @summary Get View Components
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {'update_load_unit'} viewName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewConfig(userId: string, terminalId: number, viewName: 'update_load_unit', options?: any): AxiosPromise<ViewConfig> {
            return ConfigApiFp(configuration).getViewConfig(userId, terminalId, viewName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI {
    /**
     * Get terminal configs
     * @summary Get terminal configs
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of the terminal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public getTerminalConfig(userId: string, terminalId: number, options?: any) {
        return ConfigApiFp(this.configuration).getTerminalConfig(userId, terminalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets view config
     * @summary Get View Components
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of the terminal
     * @param {'update_load_unit'} viewName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public getViewConfig(userId: string, terminalId: number, viewName: 'update_load_unit', options?: any) {
        return ConfigApiFp(this.configuration).getViewConfig(userId, terminalId, viewName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContainersApi - axios parameter creator
 * @export
 */
export const ContainersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Indicates whether a booking is possible to be booked and supplies necessary information.
         * @summary Requests the status of a loading unit
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId The terminal id of the terminal to be called
         * @param {GetStatusRequest} getStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus: async (userId: string, terminalId: number, getStatusRequest: GetStatusRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getStatus.');
            }
            // verify required parameter 'terminalId' is not null or undefined
            if (terminalId === null || terminalId === undefined) {
                throw new RequiredError('terminalId','Required parameter terminalId was null or undefined when calling getStatus.');
            }
            // verify required parameter 'getStatusRequest' is not null or undefined
            if (getStatusRequest === null || getStatusRequest === undefined) {
                throw new RequiredError('getStatusRequest','Required parameter getStatusRequest was null or undefined when calling getStatus.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/terminals/{terminalId}/containers`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof getStatusRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(getStatusRequest !== undefined ? getStatusRequest : {}) : (getStatusRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContainersApi - functional programming interface
 * @export
 */
export const ContainersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Indicates whether a booking is possible to be booked and supplies necessary information.
         * @summary Requests the status of a loading unit
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId The terminal id of the terminal to be called
         * @param {GetStatusRequest} getStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatus(userId: string, terminalId: number, getStatusRequest: GetStatusRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatusResponse>> {
            const localVarAxiosArgs = await ContainersApiAxiosParamCreator(configuration).getStatus(userId, terminalId, getStatusRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContainersApi - factory interface
 * @export
 */
export const ContainersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Indicates whether a booking is possible to be booked and supplies necessary information.
         * @summary Requests the status of a loading unit
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId The terminal id of the terminal to be called
         * @param {GetStatusRequest} getStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(userId: string, terminalId: number, getStatusRequest: GetStatusRequest, options?: any): AxiosPromise<GetStatusResponse> {
            return ContainersApiFp(configuration).getStatus(userId, terminalId, getStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContainersApi - object-oriented interface
 * @export
 * @class ContainersApi
 * @extends {BaseAPI}
 */
export class ContainersApi extends BaseAPI {
    /**
     * Indicates whether a booking is possible to be booked and supplies necessary information.
     * @summary Requests the status of a loading unit
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId The terminal id of the terminal to be called
     * @param {GetStatusRequest} getStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainersApi
     */
    public getStatus(userId: string, terminalId: number, getStatusRequest: GetStatusRequest, options?: any) {
        return ContainersApiFp(this.configuration).getStatus(userId, terminalId, getStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DepotsApi - axios parameter creator
 * @export
 */
export const DepotsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new tour object in the data base with the contained bookings
         * @summary Creates Tour
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {MobileTourCreationRequestTO} mobileTourCreationRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTour: async (userId: string, depotId: number, mobileTourCreationRequestTO: MobileTourCreationRequestTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling createTour.');
            }
            // verify required parameter 'depotId' is not null or undefined
            if (depotId === null || depotId === undefined) {
                throw new RequiredError('depotId','Required parameter depotId was null or undefined when calling createTour.');
            }
            // verify required parameter 'mobileTourCreationRequestTO' is not null or undefined
            if (mobileTourCreationRequestTO === null || mobileTourCreationRequestTO === undefined) {
                throw new RequiredError('mobileTourCreationRequestTO','Required parameter mobileTourCreationRequestTO was null or undefined when calling createTour.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/depots/{depotId}/tours`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"depotId"}}`, encodeURIComponent(String(depotId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof mobileTourCreationRequestTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(mobileTourCreationRequestTO !== undefined ? mobileTourCreationRequestTO : {}) : (mobileTourCreationRequestTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a tour object in the data base with the contained bookings
         * @summary Deletes Tour
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId id that defines the depot
         * @param {number} tourId id of the tour to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTour: async (userId: string, depotId: number, tourId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteTour.');
            }
            // verify required parameter 'depotId' is not null or undefined
            if (depotId === null || depotId === undefined) {
                throw new RequiredError('depotId','Required parameter depotId was null or undefined when calling deleteTour.');
            }
            // verify required parameter 'tourId' is not null or undefined
            if (tourId === null || tourId === undefined) {
                throw new RequiredError('tourId','Required parameter tourId was null or undefined when calling deleteTour.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/depots/{depotId}/tours/{tourId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"depotId"}}`, encodeURIComponent(String(depotId)))
                .replace(`{${"tourId"}}`, encodeURIComponent(String(tourId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all customers that belong to a depot
         * @summary Get list of all customers per depot-facilities
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomers: async (userId: string, depotId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getAllCustomers.');
            }
            // verify required parameter 'depotId' is not null or undefined
            if (depotId === null || depotId === undefined) {
                throw new RequiredError('depotId','Required parameter depotId was null or undefined when calling getAllCustomers.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/depots/{depotId}/customers`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"depotId"}}`, encodeURIComponent(String(depotId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get status of multiple container requests
         * @summary Get status of multiple container requests
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {DepotGetStatusMultiRequestTO} depotGetStatusMultiRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus1: async (userId: string, depotId: number, depotGetStatusMultiRequestTO: DepotGetStatusMultiRequestTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getStatus1.');
            }
            // verify required parameter 'depotId' is not null or undefined
            if (depotId === null || depotId === undefined) {
                throw new RequiredError('depotId','Required parameter depotId was null or undefined when calling getStatus1.');
            }
            // verify required parameter 'depotGetStatusMultiRequestTO' is not null or undefined
            if (depotGetStatusMultiRequestTO === null || depotGetStatusMultiRequestTO === undefined) {
                throw new RequiredError('depotGetStatusMultiRequestTO','Required parameter depotGetStatusMultiRequestTO was null or undefined when calling getStatus1.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/depots/{depotId}/containers`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"depotId"}}`, encodeURIComponent(String(depotId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof depotGetStatusMultiRequestTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(depotGetStatusMultiRequestTO !== undefined ? depotGetStatusMultiRequestTO : {}) : (depotGetStatusMultiRequestTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepotsApi - functional programming interface
 * @export
 */
export const DepotsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Creates a new tour object in the data base with the contained bookings
         * @summary Creates Tour
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {MobileTourCreationRequestTO} mobileTourCreationRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTour(userId: string, depotId: number, mobileTourCreationRequestTO: MobileTourCreationRequestTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourTO>> {
            const localVarAxiosArgs = await DepotsApiAxiosParamCreator(configuration).createTour(userId, depotId, mobileTourCreationRequestTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Deletes a tour object in the data base with the contained bookings
         * @summary Deletes Tour
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId id that defines the depot
         * @param {number} tourId id of the tour to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTour(userId: string, depotId: number, tourId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DepotsApiAxiosParamCreator(configuration).deleteTour(userId, depotId, tourId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all customers that belong to a depot
         * @summary Get list of all customers per depot-facilities
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCustomers(userId: string, depotId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerTO>>> {
            const localVarAxiosArgs = await DepotsApiAxiosParamCreator(configuration).getAllCustomers(userId, depotId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get status of multiple container requests
         * @summary Get status of multiple container requests
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {DepotGetStatusMultiRequestTO} depotGetStatusMultiRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatus1(userId: string, depotId: number, depotGetStatusMultiRequestTO: DepotGetStatusMultiRequestTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiStatusGetResponseTO>> {
            const localVarAxiosArgs = await DepotsApiAxiosParamCreator(configuration).getStatus1(userId, depotId, depotGetStatusMultiRequestTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DepotsApi - factory interface
 * @export
 */
export const DepotsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Creates a new tour object in the data base with the contained bookings
         * @summary Creates Tour
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {MobileTourCreationRequestTO} mobileTourCreationRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTour(userId: string, depotId: number, mobileTourCreationRequestTO: MobileTourCreationRequestTO, options?: any): AxiosPromise<TourTO> {
            return DepotsApiFp(configuration).createTour(userId, depotId, mobileTourCreationRequestTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a tour object in the data base with the contained bookings
         * @summary Deletes Tour
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId id that defines the depot
         * @param {number} tourId id of the tour to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTour(userId: string, depotId: number, tourId: number, options?: any): AxiosPromise<void> {
            return DepotsApiFp(configuration).deleteTour(userId, depotId, tourId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all customers that belong to a depot
         * @summary Get list of all customers per depot-facilities
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomers(userId: string, depotId: number, options?: any): AxiosPromise<Array<CustomerTO>> {
            return DepotsApiFp(configuration).getAllCustomers(userId, depotId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get status of multiple container requests
         * @summary Get status of multiple container requests
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {DepotGetStatusMultiRequestTO} depotGetStatusMultiRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus1(userId: string, depotId: number, depotGetStatusMultiRequestTO: DepotGetStatusMultiRequestTO, options?: any): AxiosPromise<MultiStatusGetResponseTO> {
            return DepotsApiFp(configuration).getStatus1(userId, depotId, depotGetStatusMultiRequestTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepotsApi - object-oriented interface
 * @export
 * @class DepotsApi
 * @extends {BaseAPI}
 */
export class DepotsApi extends BaseAPI {
    /**
     * Creates a new tour object in the data base with the contained bookings
     * @summary Creates Tour
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} depotId UUID that defines the depot
     * @param {MobileTourCreationRequestTO} mobileTourCreationRequestTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotsApi
     */
    public createTour(userId: string, depotId: number, mobileTourCreationRequestTO: MobileTourCreationRequestTO, options?: any) {
        return DepotsApiFp(this.configuration).createTour(userId, depotId, mobileTourCreationRequestTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a tour object in the data base with the contained bookings
     * @summary Deletes Tour
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} depotId id that defines the depot
     * @param {number} tourId id of the tour to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotsApi
     */
    public deleteTour(userId: string, depotId: number, tourId: number, options?: any) {
        return DepotsApiFp(this.configuration).deleteTour(userId, depotId, tourId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all customers that belong to a depot
     * @summary Get list of all customers per depot-facilities
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} depotId UUID that defines the depot
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotsApi
     */
    public getAllCustomers(userId: string, depotId: number, options?: any) {
        return DepotsApiFp(this.configuration).getAllCustomers(userId, depotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get status of multiple container requests
     * @summary Get status of multiple container requests
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} depotId UUID that defines the depot
     * @param {DepotGetStatusMultiRequestTO} depotGetStatusMultiRequestTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotsApi
     */
    public getStatus1(userId: string, depotId: number, depotGetStatusMultiRequestTO: DepotGetStatusMultiRequestTO, options?: any) {
        return DepotsApiFp(this.configuration).getStatus1(userId, depotId, depotGetStatusMultiRequestTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ForwardersApi - axios parameter creator
 * @export
 */
export const ForwardersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Client requests a list of forwarders
         * @summary Lists all forwarders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listForwarders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mobile/api/v1/forwarders`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForwardersApi - functional programming interface
 * @export
 */
export const ForwardersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Client requests a list of forwarders
         * @summary Lists all forwarders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listForwarders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Forwarder>>> {
            const localVarAxiosArgs = await ForwardersApiAxiosParamCreator(configuration).listForwarders(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ForwardersApi - factory interface
 * @export
 */
export const ForwardersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Client requests a list of forwarders
         * @summary Lists all forwarders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listForwarders(options?: any): AxiosPromise<Array<Forwarder>> {
            return ForwardersApiFp(configuration).listForwarders(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForwardersApi - object-oriented interface
 * @export
 * @class ForwardersApi
 * @extends {BaseAPI}
 */
export class ForwardersApi extends BaseAPI {
    /**
     * Client requests a list of forwarders
     * @summary Lists all forwarders
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwardersApi
     */
    public listForwarders(options?: any) {
        return ForwardersApiFp(this.configuration).listForwarders(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IDNOWApiApi - axios parameter creator
 * @export
 */
export const IDNOWApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Starts identification process for id now
         * @summary Starts identification process for id now
         * @param {string} userId UUID that comes from firebase authentication
         * @param {IDNOWStartIdentRequestTO} iDNOWStartIdentRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startIdentification: async (userId: string, iDNOWStartIdentRequestTO: IDNOWStartIdentRequestTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling startIdentification.');
            }
            // verify required parameter 'iDNOWStartIdentRequestTO' is not null or undefined
            if (iDNOWStartIdentRequestTO === null || iDNOWStartIdentRequestTO === undefined) {
                throw new RequiredError('iDNOWStartIdentRequestTO','Required parameter iDNOWStartIdentRequestTO was null or undefined when calling startIdentification.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/identifications`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof iDNOWStartIdentRequestTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(iDNOWStartIdentRequestTO !== undefined ? iDNOWStartIdentRequestTO : {}) : (iDNOWStartIdentRequestTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IDNOWApiApi - functional programming interface
 * @export
 */
export const IDNOWApiApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Starts identification process for id now
         * @summary Starts identification process for id now
         * @param {string} userId UUID that comes from firebase authentication
         * @param {IDNOWStartIdentRequestTO} iDNOWStartIdentRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startIdentification(userId: string, iDNOWStartIdentRequestTO: IDNOWStartIdentRequestTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDNOWLinkResponse>> {
            const localVarAxiosArgs = await IDNOWApiApiAxiosParamCreator(configuration).startIdentification(userId, iDNOWStartIdentRequestTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * IDNOWApiApi - factory interface
 * @export
 */
export const IDNOWApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Starts identification process for id now
         * @summary Starts identification process for id now
         * @param {string} userId UUID that comes from firebase authentication
         * @param {IDNOWStartIdentRequestTO} iDNOWStartIdentRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startIdentification(userId: string, iDNOWStartIdentRequestTO: IDNOWStartIdentRequestTO, options?: any): AxiosPromise<IDNOWLinkResponse> {
            return IDNOWApiApiFp(configuration).startIdentification(userId, iDNOWStartIdentRequestTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IDNOWApiApi - object-oriented interface
 * @export
 * @class IDNOWApiApi
 * @extends {BaseAPI}
 */
export class IDNOWApiApi extends BaseAPI {
    /**
     * Starts identification process for id now
     * @summary Starts identification process for id now
     * @param {string} userId UUID that comes from firebase authentication
     * @param {IDNOWStartIdentRequestTO} iDNOWStartIdentRequestTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IDNOWApiApi
     */
    public startIdentification(userId: string, iDNOWStartIdentRequestTO: IDNOWStartIdentRequestTO, options?: any) {
        return IDNOWApiApiFp(this.configuration).startIdentification(userId, iDNOWStartIdentRequestTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IspsLicensesApi - axios parameter creator
 * @export
 */
export const IspsLicensesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Validate Isps License based on license key
         * @summary Validate Isps License
         * @param {string} userId UUID that comes from firebase authentication
         * @param {IspsLicenseKeyRequestTO} ispsLicenseKeyRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateIspsLicense: async (userId: string, ispsLicenseKeyRequestTO: IspsLicenseKeyRequestTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling validateIspsLicense.');
            }
            // verify required parameter 'ispsLicenseKeyRequestTO' is not null or undefined
            if (ispsLicenseKeyRequestTO === null || ispsLicenseKeyRequestTO === undefined) {
                throw new RequiredError('ispsLicenseKeyRequestTO','Required parameter ispsLicenseKeyRequestTO was null or undefined when calling validateIspsLicense.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/licenses`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof ispsLicenseKeyRequestTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(ispsLicenseKeyRequestTO !== undefined ? ispsLicenseKeyRequestTO : {}) : (ispsLicenseKeyRequestTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IspsLicensesApi - functional programming interface
 * @export
 */
export const IspsLicensesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Validate Isps License based on license key
         * @summary Validate Isps License
         * @param {string} userId UUID that comes from firebase authentication
         * @param {IspsLicenseKeyRequestTO} ispsLicenseKeyRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateIspsLicense(userId: string, ispsLicenseKeyRequestTO: IspsLicenseKeyRequestTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await IspsLicensesApiAxiosParamCreator(configuration).validateIspsLicense(userId, ispsLicenseKeyRequestTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * IspsLicensesApi - factory interface
 * @export
 */
export const IspsLicensesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Validate Isps License based on license key
         * @summary Validate Isps License
         * @param {string} userId UUID that comes from firebase authentication
         * @param {IspsLicenseKeyRequestTO} ispsLicenseKeyRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateIspsLicense(userId: string, ispsLicenseKeyRequestTO: IspsLicenseKeyRequestTO, options?: any): AxiosPromise<void> {
            return IspsLicensesApiFp(configuration).validateIspsLicense(userId, ispsLicenseKeyRequestTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IspsLicensesApi - object-oriented interface
 * @export
 * @class IspsLicensesApi
 * @extends {BaseAPI}
 */
export class IspsLicensesApi extends BaseAPI {
    /**
     * Validate Isps License based on license key
     * @summary Validate Isps License
     * @param {string} userId UUID that comes from firebase authentication
     * @param {IspsLicenseKeyRequestTO} ispsLicenseKeyRequestTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IspsLicensesApi
     */
    public validateIspsLicense(userId: string, ispsLicenseKeyRequestTO: IspsLicenseKeyRequestTO, options?: any) {
        return IspsLicensesApiFp(this.configuration).validateIspsLicense(userId, ispsLicenseKeyRequestTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocationsApi - axios parameter creator
 * @export
 */
export const LocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Client shares its Current Location.
         * @summary Updates the location
         * @param {string} userId UUID that comes from firebase authentication
         * @param {UserLocation} userLocation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation: async (userId: string, userLocation: UserLocation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateLocation.');
            }
            // verify required parameter 'userLocation' is not null or undefined
            if (userLocation === null || userLocation === undefined) {
                throw new RequiredError('userLocation','Required parameter userLocation was null or undefined when calling updateLocation.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/locations`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userLocation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userLocation !== undefined ? userLocation : {}) : (userLocation || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationsApi - functional programming interface
 * @export
 */
export const LocationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Client shares its Current Location.
         * @summary Updates the location
         * @param {string} userId UUID that comes from firebase authentication
         * @param {UserLocation} userLocation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocation(userId: string, userLocation: UserLocation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LocationsApiAxiosParamCreator(configuration).updateLocation(userId, userLocation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LocationsApi - factory interface
 * @export
 */
export const LocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Client shares its Current Location.
         * @summary Updates the location
         * @param {string} userId UUID that comes from firebase authentication
         * @param {UserLocation} userLocation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation(userId: string, userLocation: UserLocation, options?: any): AxiosPromise<void> {
            return LocationsApiFp(configuration).updateLocation(userId, userLocation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationsApi - object-oriented interface
 * @export
 * @class LocationsApi
 * @extends {BaseAPI}
 */
export class LocationsApi extends BaseAPI {
    /**
     * Client shares its Current Location.
     * @summary Updates the location
     * @param {string} userId UUID that comes from firebase authentication
     * @param {UserLocation} userLocation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public updateLocation(userId: string, userLocation: UserLocation, options?: any) {
        return LocationsApiFp(this.configuration).updateLocation(userId, userLocation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MobileAppVersionApi - axios parameter creator
 * @export
 */
export const MobileAppVersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Increments current mobile app version. This endpoint accepts a JSON request with metadata and a multipart APK file in bytes.
         * @summary Increments mobile app version
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} appVersion 
         * @param {any} file apk file for the new version
         * @param {'android' | 'ios'} [operatingSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementMobileAppVersion: async (userId: string, appVersion: string, file: any, operatingSystem?: 'android' | 'ios', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling incrementMobileAppVersion.');
            }
            // verify required parameter 'appVersion' is not null or undefined
            if (appVersion === null || appVersion === undefined) {
                throw new RequiredError('appVersion','Required parameter appVersion was null or undefined when calling incrementMobileAppVersion.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling incrementMobileAppVersion.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/version/increment`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (operatingSystem !== undefined) {
                localVarQueryParameter['operatingSystem'] = operatingSystem;
            }

            if (appVersion !== undefined) {
                localVarQueryParameter['appVersion'] = appVersion;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Client gives mobile app version and it is validated
         * @summary Checks if mobile app version is valid
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} appVersion 
         * @param {'android' | 'ios'} [operatingSystem] 
         * @param {'android' | 'ios'} [operativeSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateMobileAppVersion: async (userId: string, appVersion: string, operatingSystem?: 'android' | 'ios', operativeSystem?: 'android' | 'ios', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling validateMobileAppVersion.');
            }
            // verify required parameter 'appVersion' is not null or undefined
            if (appVersion === null || appVersion === undefined) {
                throw new RequiredError('appVersion','Required parameter appVersion was null or undefined when calling validateMobileAppVersion.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/version`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (operatingSystem !== undefined) {
                localVarQueryParameter['operatingSystem'] = operatingSystem;
            }

            if (operativeSystem !== undefined) {
                localVarQueryParameter['operativeSystem'] = operativeSystem;
            }

            if (appVersion !== undefined) {
                localVarQueryParameter['appVersion'] = appVersion;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileAppVersionApi - functional programming interface
 * @export
 */
export const MobileAppVersionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Increments current mobile app version. This endpoint accepts a JSON request with metadata and a multipart APK file in bytes.
         * @summary Increments mobile app version
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} appVersion 
         * @param {any} file apk file for the new version
         * @param {'android' | 'ios'} [operatingSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async incrementMobileAppVersion(userId: string, appVersion: string, file: any, operatingSystem?: 'android' | 'ios', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MobileAppVersionApiAxiosParamCreator(configuration).incrementMobileAppVersion(userId, appVersion, file, operatingSystem, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Client gives mobile app version and it is validated
         * @summary Checks if mobile app version is valid
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} appVersion 
         * @param {'android' | 'ios'} [operatingSystem] 
         * @param {'android' | 'ios'} [operativeSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateMobileAppVersion(userId: string, appVersion: string, operatingSystem?: 'android' | 'ios', operativeSystem?: 'android' | 'ios', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileAppVersionValidationResponse>> {
            const localVarAxiosArgs = await MobileAppVersionApiAxiosParamCreator(configuration).validateMobileAppVersion(userId, appVersion, operatingSystem, operativeSystem, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MobileAppVersionApi - factory interface
 * @export
 */
export const MobileAppVersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Increments current mobile app version. This endpoint accepts a JSON request with metadata and a multipart APK file in bytes.
         * @summary Increments mobile app version
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} appVersion 
         * @param {any} file apk file for the new version
         * @param {'android' | 'ios'} [operatingSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementMobileAppVersion(userId: string, appVersion: string, file: any, operatingSystem?: 'android' | 'ios', options?: any): AxiosPromise<void> {
            return MobileAppVersionApiFp(configuration).incrementMobileAppVersion(userId, appVersion, file, operatingSystem, options).then((request) => request(axios, basePath));
        },
        /**
         * Client gives mobile app version and it is validated
         * @summary Checks if mobile app version is valid
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} appVersion 
         * @param {'android' | 'ios'} [operatingSystem] 
         * @param {'android' | 'ios'} [operativeSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateMobileAppVersion(userId: string, appVersion: string, operatingSystem?: 'android' | 'ios', operativeSystem?: 'android' | 'ios', options?: any): AxiosPromise<MobileAppVersionValidationResponse> {
            return MobileAppVersionApiFp(configuration).validateMobileAppVersion(userId, appVersion, operatingSystem, operativeSystem, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MobileAppVersionApi - object-oriented interface
 * @export
 * @class MobileAppVersionApi
 * @extends {BaseAPI}
 */
export class MobileAppVersionApi extends BaseAPI {
    /**
     * Increments current mobile app version. This endpoint accepts a JSON request with metadata and a multipart APK file in bytes.
     * @summary Increments mobile app version
     * @param {string} userId UUID that comes from firebase authentication
     * @param {string} appVersion 
     * @param {any} file apk file for the new version
     * @param {'android' | 'ios'} [operatingSystem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAppVersionApi
     */
    public incrementMobileAppVersion(userId: string, appVersion: string, file: any, operatingSystem?: 'android' | 'ios', options?: any) {
        return MobileAppVersionApiFp(this.configuration).incrementMobileAppVersion(userId, appVersion, file, operatingSystem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Client gives mobile app version and it is validated
     * @summary Checks if mobile app version is valid
     * @param {string} userId UUID that comes from firebase authentication
     * @param {string} appVersion 
     * @param {'android' | 'ios'} [operatingSystem] 
     * @param {'android' | 'ios'} [operativeSystem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAppVersionApi
     */
    public validateMobileAppVersion(userId: string, appVersion: string, operatingSystem?: 'android' | 'ios', operativeSystem?: 'android' | 'ios', options?: any) {
        return MobileAppVersionApiFp(this.configuration).validateMobileAppVersion(userId, appVersion, operatingSystem, operativeSystem, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MobileFacilitiesApi - axios parameter creator
 * @export
 */
export const MobileFacilitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all facilities 
         * @summary Get list of depot-facilities
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFacilities: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getAllFacilities.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/facilities`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get facility by id
         * @summary Get facility by id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of the facility
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityById: async (userId: string, facilityId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getFacilityById.');
            }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId','Required parameter facilityId was null or undefined when calling getFacilityById.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/facilities/{facilityId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get Nbs confirmation information for trucker and facility
         * @summary Get Nbs confirmation for facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of the facility
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNbsConfirmationForTruckerAndFacility: async (userId: string, facilityId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getNbsConfirmationForTruckerAndFacility.');
            }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId','Required parameter facilityId was null or undefined when calling getNbsConfirmationForTruckerAndFacility.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/facilities/{facilityId}/nbsConfirmations`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get tnc confirmation information for trucker and facility
         * @summary Get Tnc confirmation for facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of the facility
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTncConfirmationForTruckerAndFacility: async (userId: string, facilityId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getTncConfirmationForTruckerAndFacility.');
            }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId','Required parameter facilityId was null or undefined when calling getTncConfirmationForTruckerAndFacility.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/facilities/{facilityId}/tncConfirmations`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sent when user reads a qr code at facility
         * @summary User scans qr code at facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} qrFacilityId qr code Facility id
         * @param {ArriveAtFacilityRequest} arriveAtFacilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanCodeForFacility1: async (userId: string, qrFacilityId: number, arriveAtFacilityRequest: ArriveAtFacilityRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling scanCodeForFacility1.');
            }
            // verify required parameter 'qrFacilityId' is not null or undefined
            if (qrFacilityId === null || qrFacilityId === undefined) {
                throw new RequiredError('qrFacilityId','Required parameter qrFacilityId was null or undefined when calling scanCodeForFacility1.');
            }
            // verify required parameter 'arriveAtFacilityRequest' is not null or undefined
            if (arriveAtFacilityRequest === null || arriveAtFacilityRequest === undefined) {
                throw new RequiredError('arriveAtFacilityRequest','Required parameter arriveAtFacilityRequest was null or undefined when calling scanCodeForFacility1.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/facilities/{qrFacilityId}/arrival`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"qrFacilityId"}}`, encodeURIComponent(String(qrFacilityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof arriveAtFacilityRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(arriveAtFacilityRequest !== undefined ? arriveAtFacilityRequest : {}) : (arriveAtFacilityRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * set Nbs confirmation information for trucker and facility
         * @summary Set Nbs confirmation for facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of the facility
         * @param {UserNbsConfirmation} userNbsConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNbsConfirmationForTruckerAndFacility: async (userId: string, facilityId: number, userNbsConfirmation: UserNbsConfirmation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling setNbsConfirmationForTruckerAndFacility.');
            }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId','Required parameter facilityId was null or undefined when calling setNbsConfirmationForTruckerAndFacility.');
            }
            // verify required parameter 'userNbsConfirmation' is not null or undefined
            if (userNbsConfirmation === null || userNbsConfirmation === undefined) {
                throw new RequiredError('userNbsConfirmation','Required parameter userNbsConfirmation was null or undefined when calling setNbsConfirmationForTruckerAndFacility.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/facilities/{facilityId}/nbsConfirmations`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userNbsConfirmation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userNbsConfirmation !== undefined ? userNbsConfirmation : {}) : (userNbsConfirmation || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * set tnc confirmation information for trucker and facility
         * @summary Set Tnc confirmation for facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of the facility
         * @param {UserTncConfirmation} userTncConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTncConfirmationForTruckerAndFacility: async (userId: string, facilityId: number, userTncConfirmation: UserTncConfirmation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling setTncConfirmationForTruckerAndFacility.');
            }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId','Required parameter facilityId was null or undefined when calling setTncConfirmationForTruckerAndFacility.');
            }
            // verify required parameter 'userTncConfirmation' is not null or undefined
            if (userTncConfirmation === null || userTncConfirmation === undefined) {
                throw new RequiredError('userTncConfirmation','Required parameter userTncConfirmation was null or undefined when calling setTncConfirmationForTruckerAndFacility.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/facilities/{facilityId}/tncConfirmations`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userTncConfirmation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userTncConfirmation !== undefined ? userTncConfirmation : {}) : (userTncConfirmation || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileFacilitiesApi - functional programming interface
 * @export
 */
export const MobileFacilitiesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get all facilities 
         * @summary Get list of depot-facilities
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllFacilities(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Facility>>> {
            const localVarAxiosArgs = await MobileFacilitiesApiAxiosParamCreator(configuration).getAllFacilities(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get facility by id
         * @summary Get facility by id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of the facility
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFacilityById(userId: string, facilityId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Facility>> {
            const localVarAxiosArgs = await MobileFacilitiesApiAxiosParamCreator(configuration).getFacilityById(userId, facilityId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get Nbs confirmation information for trucker and facility
         * @summary Get Nbs confirmation for facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of the facility
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNbsConfirmationForTruckerAndFacility(userId: string, facilityId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNbsConfirmation>> {
            const localVarAxiosArgs = await MobileFacilitiesApiAxiosParamCreator(configuration).getNbsConfirmationForTruckerAndFacility(userId, facilityId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get tnc confirmation information for trucker and facility
         * @summary Get Tnc confirmation for facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of the facility
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTncConfirmationForTruckerAndFacility(userId: string, facilityId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserTncConfirmation>> {
            const localVarAxiosArgs = await MobileFacilitiesApiAxiosParamCreator(configuration).getTncConfirmationForTruckerAndFacility(userId, facilityId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sent when user reads a qr code at facility
         * @summary User scans qr code at facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} qrFacilityId qr code Facility id
         * @param {ArriveAtFacilityRequest} arriveAtFacilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanCodeForFacility1(userId: string, qrFacilityId: number, arriveAtFacilityRequest: ArriveAtFacilityRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckArrivalResponseTO>> {
            const localVarAxiosArgs = await MobileFacilitiesApiAxiosParamCreator(configuration).scanCodeForFacility1(userId, qrFacilityId, arriveAtFacilityRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * set Nbs confirmation information for trucker and facility
         * @summary Set Nbs confirmation for facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of the facility
         * @param {UserNbsConfirmation} userNbsConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setNbsConfirmationForTruckerAndFacility(userId: string, facilityId: number, userNbsConfirmation: UserNbsConfirmation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MobileFacilitiesApiAxiosParamCreator(configuration).setNbsConfirmationForTruckerAndFacility(userId, facilityId, userNbsConfirmation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * set tnc confirmation information for trucker and facility
         * @summary Set Tnc confirmation for facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of the facility
         * @param {UserTncConfirmation} userTncConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTncConfirmationForTruckerAndFacility(userId: string, facilityId: number, userTncConfirmation: UserTncConfirmation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MobileFacilitiesApiAxiosParamCreator(configuration).setTncConfirmationForTruckerAndFacility(userId, facilityId, userTncConfirmation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MobileFacilitiesApi - factory interface
 * @export
 */
export const MobileFacilitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get all facilities 
         * @summary Get list of depot-facilities
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFacilities(userId: string, options?: any): AxiosPromise<Array<Facility>> {
            return MobileFacilitiesApiFp(configuration).getAllFacilities(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get facility by id
         * @summary Get facility by id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of the facility
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityById(userId: string, facilityId: number, options?: any): AxiosPromise<Facility> {
            return MobileFacilitiesApiFp(configuration).getFacilityById(userId, facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * get Nbs confirmation information for trucker and facility
         * @summary Get Nbs confirmation for facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of the facility
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNbsConfirmationForTruckerAndFacility(userId: string, facilityId: number, options?: any): AxiosPromise<UserNbsConfirmation> {
            return MobileFacilitiesApiFp(configuration).getNbsConfirmationForTruckerAndFacility(userId, facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * get tnc confirmation information for trucker and facility
         * @summary Get Tnc confirmation for facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of the facility
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTncConfirmationForTruckerAndFacility(userId: string, facilityId: number, options?: any): AxiosPromise<UserTncConfirmation> {
            return MobileFacilitiesApiFp(configuration).getTncConfirmationForTruckerAndFacility(userId, facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sent when user reads a qr code at facility
         * @summary User scans qr code at facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} qrFacilityId qr code Facility id
         * @param {ArriveAtFacilityRequest} arriveAtFacilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanCodeForFacility1(userId: string, qrFacilityId: number, arriveAtFacilityRequest: ArriveAtFacilityRequest, options?: any): AxiosPromise<TruckArrivalResponseTO> {
            return MobileFacilitiesApiFp(configuration).scanCodeForFacility1(userId, qrFacilityId, arriveAtFacilityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * set Nbs confirmation information for trucker and facility
         * @summary Set Nbs confirmation for facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of the facility
         * @param {UserNbsConfirmation} userNbsConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNbsConfirmationForTruckerAndFacility(userId: string, facilityId: number, userNbsConfirmation: UserNbsConfirmation, options?: any): AxiosPromise<void> {
            return MobileFacilitiesApiFp(configuration).setNbsConfirmationForTruckerAndFacility(userId, facilityId, userNbsConfirmation, options).then((request) => request(axios, basePath));
        },
        /**
         * set tnc confirmation information for trucker and facility
         * @summary Set Tnc confirmation for facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of the facility
         * @param {UserTncConfirmation} userTncConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTncConfirmationForTruckerAndFacility(userId: string, facilityId: number, userTncConfirmation: UserTncConfirmation, options?: any): AxiosPromise<void> {
            return MobileFacilitiesApiFp(configuration).setTncConfirmationForTruckerAndFacility(userId, facilityId, userTncConfirmation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MobileFacilitiesApi - object-oriented interface
 * @export
 * @class MobileFacilitiesApi
 * @extends {BaseAPI}
 */
export class MobileFacilitiesApi extends BaseAPI {
    /**
     * Get all facilities 
     * @summary Get list of depot-facilities
     * @param {string} userId UUID that comes from firebase authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileFacilitiesApi
     */
    public getAllFacilities(userId: string, options?: any) {
        return MobileFacilitiesApiFp(this.configuration).getAllFacilities(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get facility by id
     * @summary Get facility by id
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} facilityId id of the facility
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileFacilitiesApi
     */
    public getFacilityById(userId: string, facilityId: number, options?: any) {
        return MobileFacilitiesApiFp(this.configuration).getFacilityById(userId, facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get Nbs confirmation information for trucker and facility
     * @summary Get Nbs confirmation for facility
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} facilityId id of the facility
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileFacilitiesApi
     */
    public getNbsConfirmationForTruckerAndFacility(userId: string, facilityId: number, options?: any) {
        return MobileFacilitiesApiFp(this.configuration).getNbsConfirmationForTruckerAndFacility(userId, facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get tnc confirmation information for trucker and facility
     * @summary Get Tnc confirmation for facility
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} facilityId id of the facility
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileFacilitiesApi
     */
    public getTncConfirmationForTruckerAndFacility(userId: string, facilityId: number, options?: any) {
        return MobileFacilitiesApiFp(this.configuration).getTncConfirmationForTruckerAndFacility(userId, facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sent when user reads a qr code at facility
     * @summary User scans qr code at facility
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} qrFacilityId qr code Facility id
     * @param {ArriveAtFacilityRequest} arriveAtFacilityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileFacilitiesApi
     */
    public scanCodeForFacility1(userId: string, qrFacilityId: number, arriveAtFacilityRequest: ArriveAtFacilityRequest, options?: any) {
        return MobileFacilitiesApiFp(this.configuration).scanCodeForFacility1(userId, qrFacilityId, arriveAtFacilityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * set Nbs confirmation information for trucker and facility
     * @summary Set Nbs confirmation for facility
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} facilityId id of the facility
     * @param {UserNbsConfirmation} userNbsConfirmation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileFacilitiesApi
     */
    public setNbsConfirmationForTruckerAndFacility(userId: string, facilityId: number, userNbsConfirmation: UserNbsConfirmation, options?: any) {
        return MobileFacilitiesApiFp(this.configuration).setNbsConfirmationForTruckerAndFacility(userId, facilityId, userNbsConfirmation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * set tnc confirmation information for trucker and facility
     * @summary Set Tnc confirmation for facility
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} facilityId id of the facility
     * @param {UserTncConfirmation} userTncConfirmation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileFacilitiesApi
     */
    public setTncConfirmationForTruckerAndFacility(userId: string, facilityId: number, userTncConfirmation: UserTncConfirmation, options?: any) {
        return MobileFacilitiesApiFp(this.configuration).setTncConfirmationForTruckerAndFacility(userId, facilityId, userTncConfirmation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MobileUsersApi - axios parameter creator
 * @export
 */
export const MobileUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a user for the app. Version 2
         * @summary Create user
         * @param {Trucker} trucker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserV2: async (trucker: Trucker, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'trucker' is not null or undefined
            if (trucker === null || trucker === undefined) {
                throw new RequiredError('trucker','Required parameter trucker was null or undefined when calling createUserV2.');
            }
            const localVarPath = `/mobile/api/v2/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof trucker !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(trucker !== undefined ? trucker : {}) : (trucker || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This can only be done by the logged in user.
         * @summary Delete user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteUser.');
            }
            const localVarPath = `/mobile/api/v2/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileUsersApi - functional programming interface
 * @export
 */
export const MobileUsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Create a user for the app. Version 2
         * @summary Create user
         * @param {Trucker} trucker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserV2(trucker: Trucker, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await MobileUsersApiAxiosParamCreator(configuration).createUserV2(trucker, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This can only be done by the logged in user.
         * @summary Delete user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MobileUsersApiAxiosParamCreator(configuration).deleteUser(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MobileUsersApi - factory interface
 * @export
 */
export const MobileUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Create a user for the app. Version 2
         * @summary Create user
         * @param {Trucker} trucker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserV2(trucker: Trucker, options?: any): AxiosPromise<User> {
            return MobileUsersApiFp(configuration).createUserV2(trucker, options).then((request) => request(axios, basePath));
        },
        /**
         * This can only be done by the logged in user.
         * @summary Delete user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: any): AxiosPromise<void> {
            return MobileUsersApiFp(configuration).deleteUser(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MobileUsersApi - object-oriented interface
 * @export
 * @class MobileUsersApi
 * @extends {BaseAPI}
 */
export class MobileUsersApi extends BaseAPI {
    /**
     * Create a user for the app. Version 2
     * @summary Create user
     * @param {Trucker} trucker 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileUsersApi
     */
    public createUserV2(trucker: Trucker, options?: any) {
        return MobileUsersApiFp(this.configuration).createUserV2(trucker, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This can only be done by the logged in user.
     * @summary Delete user
     * @param {string} userId UUID that comes from firebase authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileUsersApi
     */
    public deleteUser(userId: string, options?: any) {
        return MobileUsersApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OidcMobileApi - axios parameter creator
 * @export
 */
export const OidcMobileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Store Authentication Details And Return Authentication Code
         * @param {AuthDetails} authDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDetails: async (authDetails: AuthDetails, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authDetails' is not null or undefined
            if (authDetails === null || authDetails === undefined) {
                throw new RequiredError('authDetails','Required parameter authDetails was null or undefined when calling authDetails.');
            }
            const localVarPath = `/mobile/api/v1/authenticate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof authDetails !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(authDetails !== undefined ? authDetails : {}) : (authDetails || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OidcMobileApi - functional programming interface
 * @export
 */
export const OidcMobileApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Store Authentication Details And Return Authentication Code
         * @param {AuthDetails} authDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authDetails(authDetails: AuthDetails, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OidcMobileApiAxiosParamCreator(configuration).authDetails(authDetails, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OidcMobileApi - factory interface
 * @export
 */
export const OidcMobileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Store Authentication Details And Return Authentication Code
         * @param {AuthDetails} authDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDetails(authDetails: AuthDetails, options?: any): AxiosPromise<void> {
            return OidcMobileApiFp(configuration).authDetails(authDetails, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OidcMobileApi - object-oriented interface
 * @export
 * @class OidcMobileApi
 * @extends {BaseAPI}
 */
export class OidcMobileApi extends BaseAPI {
    /**
     * 
     * @summary Store Authentication Details And Return Authentication Code
     * @param {AuthDetails} authDetails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OidcMobileApi
     */
    public authDetails(authDetails: AuthDetails, options?: any) {
        return OidcMobileApiFp(this.configuration).authDetails(authDetails, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TerminalsApi - axios parameter creator
 * @export
 */
export const TerminalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get nbs confirmation information for user for terminal
         * @summary Get Nbs confirmation for terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNbsConfirmationForForUserForTerminal: async (userId: string, terminalId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getNbsConfirmationForForUserForTerminal.');
            }
            // verify required parameter 'terminalId' is not null or undefined
            if (terminalId === null || terminalId === undefined) {
                throw new RequiredError('terminalId','Required parameter terminalId was null or undefined when calling getNbsConfirmationForForUserForTerminal.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/terminals/{terminalId}/nbsConfirmations`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get terminal for user by userId
         * @summary Get terminal by id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalForUser: async (userId: string, terminalId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getTerminalForUser.');
            }
            // verify required parameter 'terminalId' is not null or undefined
            if (terminalId === null || terminalId === undefined) {
                throw new RequiredError('terminalId','Required parameter terminalId was null or undefined when calling getTerminalForUser.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/terminals/{terminalId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all terminals for user by userId
         * @summary Get terminals
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalsForUser: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getTerminalsForUser.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/terminals`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all terminals for user by userId with reduced objects
         * @summary Get terminals reduced
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalsForUserReduced: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getTerminalsForUserReduced.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/terminals/reduced`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get tnc confirmation information for user for terminal
         * @summary Get Tnc confirmation for terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTncConfirmationForForUserForTerminal: async (userId: string, terminalId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getTncConfirmationForForUserForTerminal.');
            }
            // verify required parameter 'terminalId' is not null or undefined
            if (terminalId === null || terminalId === undefined) {
                throw new RequiredError('terminalId','Required parameter terminalId was null or undefined when calling getTncConfirmationForForUserForTerminal.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/terminals/{terminalId}/tncConfirmations`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sent when user reads a qr code at facility
         * @summary User scans qr code at facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} qrFacilityId qr code Facility id
         * @param {ArriveAtFacilityRequest} arriveAtFacilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanCodeForFacility: async (userId: string, qrFacilityId: number, arriveAtFacilityRequest: ArriveAtFacilityRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling scanCodeForFacility.');
            }
            // verify required parameter 'qrFacilityId' is not null or undefined
            if (qrFacilityId === null || qrFacilityId === undefined) {
                throw new RequiredError('qrFacilityId','Required parameter qrFacilityId was null or undefined when calling scanCodeForFacility.');
            }
            // verify required parameter 'arriveAtFacilityRequest' is not null or undefined
            if (arriveAtFacilityRequest === null || arriveAtFacilityRequest === undefined) {
                throw new RequiredError('arriveAtFacilityRequest','Required parameter arriveAtFacilityRequest was null or undefined when calling scanCodeForFacility.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/terminals/{qrFacilityId}/arrival`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"qrFacilityId"}}`, encodeURIComponent(String(qrFacilityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof arriveAtFacilityRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(arriveAtFacilityRequest !== undefined ? arriveAtFacilityRequest : {}) : (arriveAtFacilityRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * set nbs confirmation information for user for terminal
         * @summary Set Nbs confirmation for terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {UserNbsConfirmation} userNbsConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNbsConfirmationForForUserForTerminal: async (userId: string, terminalId: number, userNbsConfirmation: UserNbsConfirmation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling setNbsConfirmationForForUserForTerminal.');
            }
            // verify required parameter 'terminalId' is not null or undefined
            if (terminalId === null || terminalId === undefined) {
                throw new RequiredError('terminalId','Required parameter terminalId was null or undefined when calling setNbsConfirmationForForUserForTerminal.');
            }
            // verify required parameter 'userNbsConfirmation' is not null or undefined
            if (userNbsConfirmation === null || userNbsConfirmation === undefined) {
                throw new RequiredError('userNbsConfirmation','Required parameter userNbsConfirmation was null or undefined when calling setNbsConfirmationForForUserForTerminal.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/terminals/{terminalId}/nbsConfirmations`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userNbsConfirmation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userNbsConfirmation !== undefined ? userNbsConfirmation : {}) : (userNbsConfirmation || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * set tnc confirmation information for user for terminal
         * @summary Set Tnc confirmation for terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {UserTncConfirmation} userTncConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTncConfirmationForForUserForTerminal: async (userId: string, terminalId: number, userTncConfirmation: UserTncConfirmation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling setTncConfirmationForForUserForTerminal.');
            }
            // verify required parameter 'terminalId' is not null or undefined
            if (terminalId === null || terminalId === undefined) {
                throw new RequiredError('terminalId','Required parameter terminalId was null or undefined when calling setTncConfirmationForForUserForTerminal.');
            }
            // verify required parameter 'userTncConfirmation' is not null or undefined
            if (userTncConfirmation === null || userTncConfirmation === undefined) {
                throw new RequiredError('userTncConfirmation','Required parameter userTncConfirmation was null or undefined when calling setTncConfirmationForForUserForTerminal.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/terminals/{terminalId}/tncConfirmations`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userTncConfirmation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userTncConfirmation !== undefined ? userTncConfirmation : {}) : (userTncConfirmation || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TerminalsApi - functional programming interface
 * @export
 */
export const TerminalsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get nbs confirmation information for user for terminal
         * @summary Get Nbs confirmation for terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNbsConfirmationForForUserForTerminal(userId: string, terminalId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNbsConfirmation>> {
            const localVarAxiosArgs = await TerminalsApiAxiosParamCreator(configuration).getNbsConfirmationForForUserForTerminal(userId, terminalId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get terminal for user by userId
         * @summary Get terminal by id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerminalForUser(userId: string, terminalId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Terminal>> {
            const localVarAxiosArgs = await TerminalsApiAxiosParamCreator(configuration).getTerminalForUser(userId, terminalId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all terminals for user by userId
         * @summary Get terminals
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerminalsForUser(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Terminal>>> {
            const localVarAxiosArgs = await TerminalsApiAxiosParamCreator(configuration).getTerminalsForUser(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all terminals for user by userId with reduced objects
         * @summary Get terminals reduced
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerminalsForUserReduced(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TerminalReduced>>> {
            const localVarAxiosArgs = await TerminalsApiAxiosParamCreator(configuration).getTerminalsForUserReduced(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get tnc confirmation information for user for terminal
         * @summary Get Tnc confirmation for terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTncConfirmationForForUserForTerminal(userId: string, terminalId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserTncConfirmation>> {
            const localVarAxiosArgs = await TerminalsApiAxiosParamCreator(configuration).getTncConfirmationForForUserForTerminal(userId, terminalId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sent when user reads a qr code at facility
         * @summary User scans qr code at facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} qrFacilityId qr code Facility id
         * @param {ArriveAtFacilityRequest} arriveAtFacilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanCodeForFacility(userId: string, qrFacilityId: number, arriveAtFacilityRequest: ArriveAtFacilityRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckArrivalResponseTO>> {
            const localVarAxiosArgs = await TerminalsApiAxiosParamCreator(configuration).scanCodeForFacility(userId, qrFacilityId, arriveAtFacilityRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * set nbs confirmation information for user for terminal
         * @summary Set Nbs confirmation for terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {UserNbsConfirmation} userNbsConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setNbsConfirmationForForUserForTerminal(userId: string, terminalId: number, userNbsConfirmation: UserNbsConfirmation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TerminalsApiAxiosParamCreator(configuration).setNbsConfirmationForForUserForTerminal(userId, terminalId, userNbsConfirmation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * set tnc confirmation information for user for terminal
         * @summary Set Tnc confirmation for terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {UserTncConfirmation} userTncConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTncConfirmationForForUserForTerminal(userId: string, terminalId: number, userTncConfirmation: UserTncConfirmation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TerminalsApiAxiosParamCreator(configuration).setTncConfirmationForForUserForTerminal(userId, terminalId, userTncConfirmation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TerminalsApi - factory interface
 * @export
 */
export const TerminalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * get nbs confirmation information for user for terminal
         * @summary Get Nbs confirmation for terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNbsConfirmationForForUserForTerminal(userId: string, terminalId: number, options?: any): AxiosPromise<UserNbsConfirmation> {
            return TerminalsApiFp(configuration).getNbsConfirmationForForUserForTerminal(userId, terminalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get terminal for user by userId
         * @summary Get terminal by id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalForUser(userId: string, terminalId: number, options?: any): AxiosPromise<Terminal> {
            return TerminalsApiFp(configuration).getTerminalForUser(userId, terminalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all terminals for user by userId
         * @summary Get terminals
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalsForUser(userId: string, options?: any): AxiosPromise<Array<Terminal>> {
            return TerminalsApiFp(configuration).getTerminalsForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all terminals for user by userId with reduced objects
         * @summary Get terminals reduced
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalsForUserReduced(userId: string, options?: any): AxiosPromise<Array<TerminalReduced>> {
            return TerminalsApiFp(configuration).getTerminalsForUserReduced(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * get tnc confirmation information for user for terminal
         * @summary Get Tnc confirmation for terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTncConfirmationForForUserForTerminal(userId: string, terminalId: number, options?: any): AxiosPromise<UserTncConfirmation> {
            return TerminalsApiFp(configuration).getTncConfirmationForForUserForTerminal(userId, terminalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sent when user reads a qr code at facility
         * @summary User scans qr code at facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} qrFacilityId qr code Facility id
         * @param {ArriveAtFacilityRequest} arriveAtFacilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanCodeForFacility(userId: string, qrFacilityId: number, arriveAtFacilityRequest: ArriveAtFacilityRequest, options?: any): AxiosPromise<TruckArrivalResponseTO> {
            return TerminalsApiFp(configuration).scanCodeForFacility(userId, qrFacilityId, arriveAtFacilityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * set nbs confirmation information for user for terminal
         * @summary Set Nbs confirmation for terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {UserNbsConfirmation} userNbsConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNbsConfirmationForForUserForTerminal(userId: string, terminalId: number, userNbsConfirmation: UserNbsConfirmation, options?: any): AxiosPromise<void> {
            return TerminalsApiFp(configuration).setNbsConfirmationForForUserForTerminal(userId, terminalId, userNbsConfirmation, options).then((request) => request(axios, basePath));
        },
        /**
         * set tnc confirmation information for user for terminal
         * @summary Set Tnc confirmation for terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of the terminal
         * @param {UserTncConfirmation} userTncConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTncConfirmationForForUserForTerminal(userId: string, terminalId: number, userTncConfirmation: UserTncConfirmation, options?: any): AxiosPromise<void> {
            return TerminalsApiFp(configuration).setTncConfirmationForForUserForTerminal(userId, terminalId, userTncConfirmation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TerminalsApi - object-oriented interface
 * @export
 * @class TerminalsApi
 * @extends {BaseAPI}
 */
export class TerminalsApi extends BaseAPI {
    /**
     * get nbs confirmation information for user for terminal
     * @summary Get Nbs confirmation for terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of the terminal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalsApi
     */
    public getNbsConfirmationForForUserForTerminal(userId: string, terminalId: number, options?: any) {
        return TerminalsApiFp(this.configuration).getNbsConfirmationForForUserForTerminal(userId, terminalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get terminal for user by userId
     * @summary Get terminal by id
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of the terminal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalsApi
     */
    public getTerminalForUser(userId: string, terminalId: number, options?: any) {
        return TerminalsApiFp(this.configuration).getTerminalForUser(userId, terminalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all terminals for user by userId
     * @summary Get terminals
     * @param {string} userId UUID that comes from firebase authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalsApi
     */
    public getTerminalsForUser(userId: string, options?: any) {
        return TerminalsApiFp(this.configuration).getTerminalsForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all terminals for user by userId with reduced objects
     * @summary Get terminals reduced
     * @param {string} userId UUID that comes from firebase authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalsApi
     */
    public getTerminalsForUserReduced(userId: string, options?: any) {
        return TerminalsApiFp(this.configuration).getTerminalsForUserReduced(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get tnc confirmation information for user for terminal
     * @summary Get Tnc confirmation for terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of the terminal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalsApi
     */
    public getTncConfirmationForForUserForTerminal(userId: string, terminalId: number, options?: any) {
        return TerminalsApiFp(this.configuration).getTncConfirmationForForUserForTerminal(userId, terminalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sent when user reads a qr code at facility
     * @summary User scans qr code at facility
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} qrFacilityId qr code Facility id
     * @param {ArriveAtFacilityRequest} arriveAtFacilityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalsApi
     */
    public scanCodeForFacility(userId: string, qrFacilityId: number, arriveAtFacilityRequest: ArriveAtFacilityRequest, options?: any) {
        return TerminalsApiFp(this.configuration).scanCodeForFacility(userId, qrFacilityId, arriveAtFacilityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * set nbs confirmation information for user for terminal
     * @summary Set Nbs confirmation for terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of the terminal
     * @param {UserNbsConfirmation} userNbsConfirmation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalsApi
     */
    public setNbsConfirmationForForUserForTerminal(userId: string, terminalId: number, userNbsConfirmation: UserNbsConfirmation, options?: any) {
        return TerminalsApiFp(this.configuration).setNbsConfirmationForForUserForTerminal(userId, terminalId, userNbsConfirmation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * set tnc confirmation information for user for terminal
     * @summary Set Tnc confirmation for terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of the terminal
     * @param {UserTncConfirmation} userTncConfirmation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalsApi
     */
    public setTncConfirmationForForUserForTerminal(userId: string, terminalId: number, userTncConfirmation: UserTncConfirmation, options?: any) {
        return TerminalsApiFp(this.configuration).setTncConfirmationForForUserForTerminal(userId, terminalId, userTncConfirmation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * add deviceInformation for certain user
         * @summary add device information
         * @param {string} userId UUID that comes from firebase authentication
         * @param {DeviceInformation} deviceInformation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeviceInformationForUser: async (userId: string, deviceInformation: DeviceInformation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling addDeviceInformationForUser.');
            }
            // verify required parameter 'deviceInformation' is not null or undefined
            if (deviceInformation === null || deviceInformation === undefined) {
                throw new RequiredError('deviceInformation','Required parameter deviceInformation was null or undefined when calling addDeviceInformationForUser.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/devicesInformation`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof deviceInformation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(deviceInformation !== undefined ? deviceInformation : {}) : (deviceInformation || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a user for the app.
         * @summary Create user
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (user: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            if (user === null || user === undefined) {
                throw new RequiredError('user','Required parameter user was null or undefined when calling createUser.');
            }
            const localVarPath = `/mobile/api/v1/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof user !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(user !== undefined ? user : {}) : (user || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This can only be done by the logged in user.
         * @summary Delete user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser1: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteUser1.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user by Id
         * @summary Get user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} [deviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (userId: string, deviceId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getUserById.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * remove deviceInformation for certain user
         * @summary remove device information
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} deviceToken Device token that comes from firebase
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDeviceInformationForUser: async (userId: string, deviceToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling removeDeviceInformationForUser.');
            }
            // verify required parameter 'deviceToken' is not null or undefined
            if (deviceToken === null || deviceToken === undefined) {
                throw new RequiredError('deviceToken','Required parameter deviceToken was null or undefined when calling removeDeviceInformationForUser.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}/devicesInformation/{deviceToken}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"deviceToken"}}`, encodeURIComponent(String(deviceToken)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * resets password for trucker
         * @summary reset password
         * @param {ResetPassword} resetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPassword: ResetPassword, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPassword' is not null or undefined
            if (resetPassword === null || resetPassword === undefined) {
                throw new RequiredError('resetPassword','Required parameter resetPassword was null or undefined when calling resetPassword.');
            }
            const localVarPath = `/mobile/api/v1/users/resetPassword`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof resetPassword !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resetPassword !== undefined ? resetPassword : {}) : (resetPassword || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This can only be done by the logged in user.
         * @summary Updates user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, user: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateUser.');
            }
            // verify required parameter 'user' is not null or undefined
            if (user === null || user === undefined) {
                throw new RequiredError('user','Required parameter user was null or undefined when calling updateUser.');
            }
            const localVarPath = `/mobile/api/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof user !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(user !== undefined ? user : {}) : (user || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sends verify email for trucker
         * @summary verify email
         * @param {VerifyEmail} verifyEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail: async (verifyEmail: VerifyEmail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyEmail' is not null or undefined
            if (verifyEmail === null || verifyEmail === undefined) {
                throw new RequiredError('verifyEmail','Required parameter verifyEmail was null or undefined when calling verifyEmail.');
            }
            const localVarPath = `/mobile/api/v1/users/verifyEmail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof verifyEmail !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(verifyEmail !== undefined ? verifyEmail : {}) : (verifyEmail || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * add deviceInformation for certain user
         * @summary add device information
         * @param {string} userId UUID that comes from firebase authentication
         * @param {DeviceInformation} deviceInformation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDeviceInformationForUser(userId: string, deviceInformation: DeviceInformation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).addDeviceInformationForUser(userId, deviceInformation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create a user for the app.
         * @summary Create user
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(user: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).createUser(user, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This can only be done by the logged in user.
         * @summary Delete user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser1(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).deleteUser1(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get user by Id
         * @summary Get user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} [deviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(userId: string, deviceId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).getUserById(userId, deviceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * remove deviceInformation for certain user
         * @summary remove device information
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} deviceToken Device token that comes from firebase
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeDeviceInformationForUser(userId: string, deviceToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).removeDeviceInformationForUser(userId, deviceToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * resets password for trucker
         * @summary reset password
         * @param {ResetPassword} resetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPassword: ResetPassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).resetPassword(resetPassword, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This can only be done by the logged in user.
         * @summary Updates user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, user: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).updateUser(userId, user, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * sends verify email for trucker
         * @summary verify email
         * @param {VerifyEmail} verifyEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEmail(verifyEmail: VerifyEmail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).verifyEmail(verifyEmail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * add deviceInformation for certain user
         * @summary add device information
         * @param {string} userId UUID that comes from firebase authentication
         * @param {DeviceInformation} deviceInformation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeviceInformationForUser(userId: string, deviceInformation: DeviceInformation, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).addDeviceInformationForUser(userId, deviceInformation, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a user for the app.
         * @summary Create user
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(user: User, options?: any): AxiosPromise<User> {
            return UsersApiFp(configuration).createUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * This can only be done by the logged in user.
         * @summary Delete user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser1(userId: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).deleteUser1(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user by Id
         * @summary Get user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} [deviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(userId: string, deviceId?: string, options?: any): AxiosPromise<User> {
            return UsersApiFp(configuration).getUserById(userId, deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * remove deviceInformation for certain user
         * @summary remove device information
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} deviceToken Device token that comes from firebase
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDeviceInformationForUser(userId: string, deviceToken: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).removeDeviceInformationForUser(userId, deviceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * resets password for trucker
         * @summary reset password
         * @param {ResetPassword} resetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPassword: ResetPassword, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).resetPassword(resetPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * This can only be done by the logged in user.
         * @summary Updates user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, user: User, options?: any): AxiosPromise<User> {
            return UsersApiFp(configuration).updateUser(userId, user, options).then((request) => request(axios, basePath));
        },
        /**
         * sends verify email for trucker
         * @summary verify email
         * @param {VerifyEmail} verifyEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail(verifyEmail: VerifyEmail, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).verifyEmail(verifyEmail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * add deviceInformation for certain user
     * @summary add device information
     * @param {string} userId UUID that comes from firebase authentication
     * @param {DeviceInformation} deviceInformation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public addDeviceInformationForUser(userId: string, deviceInformation: DeviceInformation, options?: any) {
        return UsersApiFp(this.configuration).addDeviceInformationForUser(userId, deviceInformation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a user for the app.
     * @summary Create user
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(user: User, options?: any) {
        return UsersApiFp(this.configuration).createUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This can only be done by the logged in user.
     * @summary Delete user
     * @param {string} userId UUID that comes from firebase authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser1(userId: string, options?: any) {
        return UsersApiFp(this.configuration).deleteUser1(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user by Id
     * @summary Get user
     * @param {string} userId UUID that comes from firebase authentication
     * @param {string} [deviceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserById(userId: string, deviceId?: string, options?: any) {
        return UsersApiFp(this.configuration).getUserById(userId, deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * remove deviceInformation for certain user
     * @summary remove device information
     * @param {string} userId UUID that comes from firebase authentication
     * @param {string} deviceToken Device token that comes from firebase
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public removeDeviceInformationForUser(userId: string, deviceToken: string, options?: any) {
        return UsersApiFp(this.configuration).removeDeviceInformationForUser(userId, deviceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * resets password for trucker
     * @summary reset password
     * @param {ResetPassword} resetPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetPassword(resetPassword: ResetPassword, options?: any) {
        return UsersApiFp(this.configuration).resetPassword(resetPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This can only be done by the logged in user.
     * @summary Updates user
     * @param {string} userId UUID that comes from firebase authentication
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(userId: string, user: User, options?: any) {
        return UsersApiFp(this.configuration).updateUser(userId, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * sends verify email for trucker
     * @summary verify email
     * @param {VerifyEmail} verifyEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public verifyEmail(verifyEmail: VerifyEmail, options?: any) {
        return UsersApiFp(this.configuration).verifyEmail(verifyEmail, options).then((request) => request(this.axios, this.basePath));
    }
}


