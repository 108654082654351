import { defineStore } from "pinia";
import Snackbar from "@/store/interfaces/snackbar";
import { createSnackBar } from "@/utils/snackbar-utils";
import SnackbarOptions from "@/store/interfaces/snackbar-options";
import SnackbarType from "@/store/interfaces/snackbar-type";
import { SnackbarState } from "@/store/module-states";

export const useSnackbarStore = defineStore("snackbar", {
  state: (): SnackbarState => ({
    snackbarState: createSnackBar("", SnackbarType.INITIAL),
  }),

  actions: {
    setSnackbarData(snackbar: Snackbar) {
      this.snackbarState = snackbar;
    },

    clearSnackbar() {
      const initialSnackbar = createSnackBar("", SnackbarType.INITIAL);
      this.setSnackbarData(initialSnackbar);
    },

    showSnackbar(options: SnackbarOptions) {
      const snackbar = createSnackBar(options.text, options.snackbarType);
      this.setSnackbarData(snackbar);

      setTimeout(() => {
        this.clearSnackbar();
      }, 5000);
    },
  },
});
