<template>
  <v-card
    class="overlay pa-5 white black--text"
    data-testid="test-loggedInUserTile"
  >
    <v-icon size="30" color="green">mdi-check</v-icon>
    {{ t("oidc.loggingInAsUser", { userName: username.toUpperCase() }) }}
  </v-card>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

defineProps({
  username: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();
</script>

<style scoped>
.overlay {
  height: 100%;
}
</style>
