<template>
  <v-card :width="calculateWidth" :loading="loadingResetPassword">
    <v-main fluid class="fill-height pa-4">
      <v-row>
        <v-col lg="6" md="12" class="d-flex align-center justify-center">
          <div class="w-100 mx-8">
            <div class="text-h4 font-weight-bold text-center">
              {{ $t("forgotPassword") }}
            </div>

            <v-card-text class="mt-8">
              <v-form v-model="valid">
                <v-text-field
                  v-model="email"
                  type="email"
                  prepend-icon="mdi-email"
                  :rules="[
                    (v: string) =>
                      /^[^\s@]+@[^\s@]+$/.test(v) || $t('invalidEmail'),
                  ]"
                  :error-messages="errorMessages"
                  label="E-mail"
                  variant="outlined"
                  :placeholder="$t('placeholderEmail')"
                  required
                  :disabled="loadingResetPassword"
                  :loading="loadingResetPassword"
                />

                <TheButton
                  :is-disabled="loadingResetPassword"
                  :color="'primary'"
                  :size="'large'"
                  :rounded="'lg'"
                  @clicked-button="sendResetEmail"
                  :elevation="0"
                  class="mt-6"
                  :title="$t('sendResetEmail')"
                />
              </v-form>
            </v-card-text>

            <TheErrorBox
              class="ml-15 mt-2"
              :title="forgotPasswordErrorMessage"
            />

            <TheMainText
              @click="changeFormView"
              :clickable="true"
              :title="$t('back_to_login')"
            />
          </div>
        </v-col>
        <v-col
          v-if="!$vuetify.display.mdAndDown"
          cols="6"
          class="d-flex align-center justify-center"
        >
          <div class="animation-container rounded-lg">
            <Vue3Lottie
              :animation-data="ForgotPassword"
              :loop="true"
              :autoplay="true"
              :height="450"
              :max-height="450"
            />
          </div>
        </v-col>
      </v-row>
    </v-main>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { Vue3Lottie } from "vue3-lottie";
import { useI18n } from "vue-i18n";
import TheButton from "@/components/TheButton.vue";
import TheMainText from "@/components/TheMainText.vue";
import TheErrorBox from "@/components/TheErrorBox.vue";
import { getErrorByType } from "@/utils/error-utils";
import { getViewModel } from "./login-page-logic";
import ForgotPassword from "../../../lottie/ForgotPassword.json";
import { useDisplay } from "vuetify";
import { useProcess } from "@/composables/useProcess";

const emit = defineEmits(["changed-login-view"]);
const { t } = useI18n();
const { name } = useDisplay();

const viewModel = getViewModel();

const valid = ref(false);
const forgotPasswordErrorMessage = ref("");
const loadingResetPassword = ref(false);
const errorMessages = ref("");
const email = ref("");

// computed properties
const validate = computed(() => valid.value);

// Methods
const { startProcess, stopProcess } = useProcess(
  forgotPasswordErrorMessage,
  loadingResetPassword,
);

const sendResetEmail = async (): Promise<void> => {
  if (!validate.value) {
    forgotPasswordErrorMessage.value = "";
    return;
  }
  if (loadingResetPassword.value) return;
  startProcess();
  try {
    await viewModel.resetPassword(email.value);
    changeFormView();
    await viewModel.showEmailSentSnackbar();
  } catch (e) {
    forgotPasswordErrorMessage.value = t(getErrorByType(e)) as string;
  } finally {
    stopProcess();
  }
};

const changeFormView = (): void => {
  emit("changed-login-view");
};

const calculateWidth = computed(() => {
  switch (name.value) {
    case "xl":
      return "50vw";
    case "lg":
      return "60vw";
    case "md":
      return "60vw";
    case "sm":
      return "80vw";
    case "xs":
      return "90vw";
    default:
      return "100vw";
  }
});
</script>

<style lang="css">
.animation-container {
  background-color: #14005c;
}
</style>
