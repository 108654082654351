import firebase from "firebase/compat/app";
import { auth } from "../../../firebase";

export class AuthService {
  constructor(private auth: firebase.auth.Auth) {}

  async login(email: string, password: string): Promise<firebase.User | null> {
    const data = await this.auth.signInWithEmailAndPassword(email, password);
    if (data) {
      const idToken = await this.getToken();
      localStorage.setItem("accessToken", idToken ?? "");
    }
    return data?.user;
  }

  async signInWithPhoneNumber(
    phone: string,
    appVerifier: firebase.auth.RecaptchaVerifier,
  ): Promise<firebase.auth.ConfirmationResult | null> {
    return await this.auth.signInWithPhoneNumber(phone, appVerifier);
  }

  async logout(): Promise<void> {
    await this.auth.signOut();
  }

  async getToken(): Promise<string | undefined> {
    const user = this.auth.currentUser;
    if (user) return await user.getIdToken(true);
  }
}

export const authService = new AuthService(auth);
