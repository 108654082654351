const IconAssets: Record<string, string> = {
  //material icons
  ACCEPTANCE_DEADLINE: "mdi-view-dashboard",
  HEADPHONES: "mdi-headphones-box",
  MENU: "mdi-menu",
  LOGOUT: "mdi-logout",
  SUCCESS: " mdi-checkbox-marked-circle",
  WARNING: "mdi-alert",
  ERROR: "mdi-alert-circle",
  ACCOUNT: "mdi-account",
  HOME_ICON: "mdi-home",

  //svg icons
  HOME: "home",
  CONTAINER: "container",
  TRAIN: "train",
  TRUCK: "truck",
};

export default IconAssets;
