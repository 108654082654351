interface ErrorResponse {
  response?: {
    data?: {
      code?: string;
    };
    status?: number;
  };
  code?: string;
}

function isErrorResponse(error: unknown): error is ErrorResponse {
  return typeof error === "object" && error !== null && "response" in error;
}

function isErrorWithCode(error: unknown): error is { code: string } {
  return typeof error === "object" && error !== null && "code" in error;
}

export function getErrorByType(error: unknown): string {
  // Check if error is of type ErrorResponse
  if (isErrorResponse(error)) {
    if (error.response?.data?.code) {
      return error.response.data.code;
    }
    if (error.response?.status === 403 || error.response?.status === 401) {
      return "user_not_found";
    }
  }

  // Check if error has a code (for Firebase or other errors)
  if (isErrorWithCode(error)) {
    return error.code;
  }

  return "unknownError";
}
