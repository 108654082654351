import { OidcMobileApi, AuthDetails } from "@/services/client/generated";
import { Composer } from "vue-i18n";
import { oidcLoginService } from "@/services/client/configs/services";
import i18n from "@/plugins/i18n";

export class OIDCLoginLogic {
  constructor(
    private oidcLoginService: OidcMobileApi,
    private vueI18n: Composer,
  ) {}

  async refreshToken(authDetails: AuthDetails): Promise<string | unknown> {
    const response = await this.oidcLoginService.authDetails(authDetails);
    return response.data;
  }
}

let viewModel: OIDCLoginLogic | null = null;

export const getViewModel = (): OIDCLoginLogic => {
  if (!viewModel) {
    viewModel = new OIDCLoginLogic(oidcLoginService, i18n.global as Composer);
  }
  return viewModel;
};
